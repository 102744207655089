import React, { useEffect } from 'react';
import { Slider as SliderMui, SliderThumb, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RangeSliderProps } from './SliderProps';
import style from './style';

const StyledSlider = styled(SliderMui)(({ theme }) => ({
  margin: '24px 0 12px 0',
  width: '95%',
  marginLeft: '2.5%',
  color: '#404040',
  height: 3,
  padding: '0px 0 13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: 'none'
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1
    }
  },
  '& .MuiSlider-track': {
    color: '#404040',
    height: 3
  },
  '& .MuiSlider-rail': {
    color: '#f2f2f2',
    height: 3
  }
}));

type AirbnbThumbComponentProps = React.HTMLAttributes<unknown>;

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

const SliderTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  color: '#757575',
  marginTop: '24px'
});

export default function RangeSlider(props: RangeSliderProps) {
  const { testID, name, title, value, max, min, onChange, onChangeCommitted, ...rest } = props;
  return (
    <Typography component="div" style={style.root}>
      {title && (
        <SliderTypography id="range-slider" gutterBottom>
          {title}
        </SliderTypography>
      )}
      <StyledSlider
        slots={{ thumb: AirbnbThumbComponent }}
        value={value}
        min={min}
        max={max}
        step={100}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        data-testid={`slider-${testID}`}
      />
    </Typography>
  );
}
