import { makeAutoObservable } from 'mobx';

import { RootStore } from './RootStore.d';
import uniqueId from '../utils/uniqueId';
import {
  FlashMessageData,
  FlashMessageOptions,
  FlashMessageStatus
} from '../components/flash-messages-service/flashMessageTypes';

type FlashMessageStoreInitialState = {
  flashMessages: FlashMessageData[];
};

const flashMessageStoreInitialState: FlashMessageStoreInitialState = {
  flashMessages: []
};

class FlashMessageStore {
  root: RootStore;

  store = flashMessageStoreInitialState;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }

  resetStore = () => {
    this.store = flashMessageStoreInitialState;
  };

  addFlashMessage = (options: FlashMessageOptions) => {
    const { flashMessages } = this.store;
    const {
      status = FlashMessageStatus.success,
      message,
      duration = 6000,
      delay = 0,
      withProgressBar = false,
      closeOnClick = true
    } = options;

    flashMessages.push({
      id: uniqueId(),
      status,
      message,
      duration,
      delay,
      withProgressBar,
      closeOnClick
    });
  };

  removeFlashMessage = (flashMessage: FlashMessageData) => {
    this.store.flashMessages = this.store.flashMessages.filter((fm) => fm.id !== flashMessage.id);
  };
}

export default FlashMessageStore;
