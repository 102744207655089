import React, { useState } from 'react';
import { Icon } from '@lightricks/react-design-system';
import { Logout, QuestionMarkOutlineIcon } from '../../icons';
import styles from './SecondaryMenu.module.scss';
import MenuItem from '../menu-item';
import mainMenuStyle from '../main-menu/MainManu.module.scss';
import useLogout from '../../../hooks/use-logout';
import { MenuProps } from '../MenuProps';
import { SUPPORT_LINK } from '../../../config/constants';
import useNavigation from '../../../hooks/use-navigation';
import getStores from '../../../utils/getStores';
import translate from '../../../utils/translate';
import ReportInappropriateContent from '../../report-inappropriate-content';
import EmberIframe from '../../ember-iframe';

function SecondaryMenu(props: MenuProps) {
  const { trackEventClickMenuItem } = props;

  const { rootStore } = getStores('rootStore');
  const navigation = useNavigation();
  const { logout } = useLogout();

  const [modalReportIsOpen, setModalReportIsOpen] = useState(false);
  EmberIframe.listen('report', (data) => {
    openReportModal();
  });
  const openReportModal = () => {
    setModalReportIsOpen(true);
  };

  const closeReportModal = () => {
    setModalReportIsOpen(false);
  };
  const logOut = () => {
    rootStore.setRedirectUrlAfterAuth(window.location.pathname);
    navigation.navigate({ screen: '', url: '/' });
    trackEventClickMenuItem(translate('components.user-toolbar.logout'), '/auth/login', false);
    logout();
  };
  const testID = 'secondary-menu';
  return (
    <div className={styles.secondaryMenu}>
      <div
        role="button"
        tabIndex={0}
        onClick={openReportModal}
        className={styles.container}
        data-testid={`${testID}--report`}>
        <Icon size="medium" appearance="neutral" name="Navigation-Flag" />
        <div className={styles.textContainer}>
          <span className={styles.text}>{translate('components.user-toolbar.report')}</span>
        </div>
      </div>
      <ReportInappropriateContent
        isOpen={modalReportIsOpen}
        onClose={closeReportModal}
        testID="report-inappropriate-content"
      />
      <a
        href={SUPPORT_LINK}
        target="_blank"
        className={mainMenuStyle.menuLink}
        rel="noreferrer"
        onClick={() =>
          trackEventClickMenuItem(translate('components.user-toolbar.support'), SUPPORT_LINK, true)
        }>
        <MenuItem
          isActive={false}
          title={translate('components.user-toolbar.support')}
          icon={QuestionMarkOutlineIcon}
        />
      </a>

      <button
        data-testid="menu--logout-button"
        type="button"
        title={translate('components.user-toolbacore/AppProviders.tsxr.logout')}
        onClick={logOut}
        className={styles.logOutButton}>
        <MenuItem
          isActive={false}
          title={translate('components.user-toolbar.logout')}
          icon={Logout}
        />
      </button>
    </div>
  );
}

export default SecondaryMenu;
