import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { snakeCase } from 'lodash';
import styles from './EmberIframe.module.scss';
import Delta from '../../utils/wrappers/Delta';
import FiltersPopup from '../filters-popup/FiltersPopup';
import { getEnv } from '../../config/environment';
import useNavigation from '../../hooks/use-navigation';
import mapKeysToCase from '../../utils/mapKeysToCase';
import getStores from '../../utils/getStores';
import useToken from '../../hooks/use-token';

type EmberIframeProps = {
  src?: string;
  isFullHeight?: boolean;
};

const requestIds: { [key: string]: boolean } = {};

function useEmberUrlChange() {
  const navigation = useNavigation();

  useEffect(() => {
    const removeChangeUrlListener = EmberIframe.listen('changeUrl', (uri) => {
      // This only change the URL in the browser but doesn't trigger any component changes
      window.history.replaceState(null, '', uri);
      // If the uri is defined by React Route then we also want to navigate to there
      navigation.navigate({ screen: '', url: uri }, { replace: true });
    });
    return removeChangeUrlListener;
  }, []);
}

function useEmberRequestDelegation() {
  useEffect(() => {
    const removeAjaxRequestListener = EmberIframe.listen('ajaxRequest', async (data) => {
      if (!window.Cypress) {
        throw new Error('Cannot intercept ajax requests without Cypress running');
      }
      const { url, type, options, headers, requestId } = JSON.parse(data);
      if (requestIds[requestId]) {
        return;
      }
      requestIds[requestId] = true;
      const requestURL =
        type === 'GET' && options?.data ? `${url}?${new URLSearchParams(options.data)}` : url;
      const response = await fetch(requestURL, {
        method: type,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          ...headers
        },
        body: type !== 'GET' && options?.data ? JSON.stringify(options.data) : undefined
      });
      let responseData = null;
      const { status, headers: responseHeaders } = response;
      try {
        responseData = await response.json();
      } catch (e) {
        console.error('response.json() error:', { e });
      }
      EmberIframe.send(
        `ajaxResponse:${requestId}`,
        JSON.stringify({
          data: mapKeysToCase(responseData, snakeCase),
          status,
          headers: responseHeaders
        })
      );
    });
    return removeAjaxRequestListener;
  }, []);
}

function EmberIframe(props: EmberIframeProps) {
  const { token } = useToken();
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [isAuthRequested, setIsAuthRequested] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = React.useState(false);
  const { rootStore } = getStores('rootStore');
  const navigation = useNavigation();

  const { src, isFullHeight } = props;
  EmberIframe.listen('authRequest', () => {
    setIsAuthRequested(true);
  });

  useEffect(() => {
    EmberIframe.listen(
      'sendDeltaEvent',
      ({ eventName, eventData }: { eventName: string; eventData: object }) => {
        Delta.sendEvent(eventName, eventData);
      }
    );
  }, []);

  useEmberUrlChange();
  useEmberRequestDelegation();

  const location = useLocation();

  useEffect(() => {
    if (isAuthRequested && isIframeLoaded) {
      EmberIframe.send('token', token);
    }
  }, [isIframeLoaded, isAuthRequested]);

  useEffect(() => {
    if (token && isIframeLoaded && rootStore.redirectUrlAfterAuth) {
      navigation.navigate({ screen: '', url: rootStore.redirectUrlAfterAuth });
      rootStore.setRedirectUrlAfterAuth(null);
    }
  }, [token, isIframeLoaded]);

  useEffect(() => {
    if (isIframeLoaded) {
      EmberIframe.send('changeUrl', location);
    }
  }, [location, isIframeLoaded]);

  const iframeFullHeightStyle = isFullHeight ? styles.iframeFullHeight : '';

  const onLoad = () => {
    setIsIframeLoaded(true);
  };

  EmberIframe.listen('openFiltersPopupWindow', () => {
    setShowFilterPopup(true);
  });

  const IframeSingleton = useMemo(
    () => (
      <iframe
        name={`ember-iframe${window.Cypress ? '-cypress' : ''}`}
        id="ember-iframe"
        data-cy="ember-iframe"
        className={`${styles.iframe} ${iframeFullHeightStyle}`}
        onLoad={onLoad}
        src={src || `${getEnv().VITE_DOMAIN_HOST_IFRAME}${location.pathname}${location.search}`}
        title="Ember iFrame"
      />
    ),
    []
  );
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<FiltersPopup showPopup={showFilterPopup} setShowPopup={setShowFilterPopup} />}
        />
      </Routes>
      {IframeSingleton}
    </>
  );
}
const EMBER_URL = getEnv().VITE_DOMAIN_HOST_IFRAME;

EmberIframe.send = (subject: string, message: any) => {
  const iframe: HTMLIFrameElement | null = document.querySelector('#ember-iframe');
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage({ subject, message }, EMBER_URL);
  } else {
    console.error('No ember iframe found.');
  }
};

EmberIframe.listen = (acceptedEventName: string, callback: (data: any) => void) => {
  const onMessage = (e: MessageEvent) => {
    const eventName = e.data.subject;
    const eventData = e.data.message;

    if (e.origin === EMBER_URL && eventName === acceptedEventName) {
      callback(eventData);
    }
  };
  window.addEventListener('message', onMessage, false);
  return () => window.removeEventListener('message', onMessage);
};
export default EmberIframe;
