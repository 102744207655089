import api from '../index';

const verticalTagsFetchers = {
  verticalTags: () =>
    api.get('/vertical_tags', {
      params: {}
    })
};

export default verticalTagsFetchers;
