import React from 'react';
import { observer } from 'mobx-react';
import ModalTitle from './modal-title';
import FiltersPopupActionButtons from './filters-popup-action-buttons/FiltersPopupActionButtons';
import FiltersPopupCanvas from './filters-popup-canvas/FiltersPopupCanvas';
import useNavigation from '../../hooks/use-navigation';
import getStores from '../../utils/getStores';
import Modal from '../modal';

function FiltersPopup(props: { showPopup: boolean; setShowPopup: (val: boolean) => void }) {
  const { showPopup, setShowPopup } = props;

  const { filterPopupParametersStore } = getStores('filterPopupParametersStore');

  const navigation = useNavigation();

  const updateFilters = filterPopupParametersStore.updateFilterPopupParametersByKeys;
  const filters = filterPopupParametersStore.filterPopupParameters;
  const queryParamsList = filters.currentQueryParamsList;
  const queryParamsUrl = filters.currentQueryParamsUrl;
  const resetFilters = filterPopupParametersStore.resetPopupParameters;

  const handleClose = () => {
    setShowPopup(false);
  };

  function updateUrl() {
    navigation.navigate({ screen: '', url: `/?${queryParamsUrl}` });
    handleClose();
  }

  function resetUrl() {
    navigation.navigate({ screen: '', url: '/' });
    resetFilters();
  }

  return (
    <Modal
      dividers
      open={showPopup}
      scroll="paper"
      onClose={handleClose}
      actions={
        <FiltersPopupActionButtons
          paramList={queryParamsList}
          onShowGigsClick={() => {
            updateUrl();
          }}
          onResetClick={() => resetUrl()}
        />
      }
      title={<ModalTitle title="Filters" onCloseButtonClick={handleClose} />}>
      <FiltersPopupCanvas updateFilters={updateFilters} filters={filters} />
    </Modal>
  );
}

export default observer(FiltersPopup);
