import api from '../index';

const campaigns = {
  counter: (params: any) =>
    api.get('/user/campaign_list_items/count', {
      params: {
        filter: 'without_invites,count',
        include: ['preferred_creator_profile,deliverables'],
        ...params
      }
    })
};

export default campaigns;
