import { getEnv } from '../../config/environment';

type Config = {
  dsn: string;
  debug?: boolean; // default: false
  dist?: string;
  release?: string;
  environment?: string;
  tunnel?: string;
  sampleRate?: number; // default: 1.0
  maxBreadcrumbs?: number; // default: 100
  attachStacktrace?: boolean;
  denyUrls?: [string];
  allowUrls?: [string];
  autoSessionTracking?: boolean;
  maxValueLength?: number; // default: 250
  normalizeDepth?: number; // default: 3
  normalizeMaxBreadth?: number; // default: 1000
  enabled?: boolean; // default: true
  integrations?: [object];
  defaultIntegrations?: boolean;
  beforeBreadcrumb?(breadcrumb: Breadcrumb, hint: unknown): Breadcrumb | null;
  tracesSampleRate?: number;
  initialScope?: object;
};

type User = {
  id?: string;
  email?: string;
  username?: string;
  segment?: string;
  ip_address?: string;
} | null;

type Context = object;

type Breadcrumb = {
  type: string;
  category?: string;
  message?: string;
  level?: 'fatal' | 'critical' | 'error' | 'warning' | 'log' | 'info' | 'debug';
  timestamp?: string | number;
  data?: object;
};

type Scope = {
  setExtras(extra: object): void;
};

export interface SentryDriver {
  setDriver(driver: SentryDriver | any, debugMode: boolean): void;

  init(config: Config): void;

  captureException(err: object): void;

  captureMessage(message: string): void;

  withScope(callback: (scope: Scope) => void): void;

  setUser(user: User): void;

  setContext(contextName: string, context: Context): void;

  setTag(key: string, value: unknown): void;

  setExtra(key: string, value: unknown): void;

  addBreadcrumb(breadcrumb: Breadcrumb): void;

  nativeCrash?(): void;
}

/**
 * Usage: Initialization
 * set a sentry driver as soon as the app starts
 *
 * import Sentry from 'src/utils/wrappers/Sentry';
 * import * as SentryDriver from '@sentry/react';

 * Sentry.setDriver(SentryDriver, DEBUG_MODE); // DEBUG_MODE=true => for testing in development environment
 * Sentry.init({
 *   dsn: SENTRY_DSN,
 * });
 */
class Sentry {
  private driver: SentryDriver | undefined;

  debugMode = false;

  /**
   * set sentry driver
   * @param driver
   * @param debugMode
   */
  setDriver(driver: SentryDriver | any, debugMode = false) {
    this.driver = driver;
    this.debugMode = debugMode;
  }

  /**
   * flag that indicates if sentry is enabled or not
   * @returns {boolean}
   */
  isEnabled(): boolean {
    return getEnv().MODE !== 'development' || this.debugMode;
  }

  /**
   * initialize sentry
   * @param config - object
   */
  init(config: Config) {
    if (!config.dsn && this.isEnabled()) {
      throw new Error('Could not initialize Sentry due to missing DSN');
    }

    this.checkDriver();

    if (this.isEnabled()) {
      this.driver!.init(config);
    }
  }

  /**
   * add user information
   * @param user
   */
  setUser(user: User) {
    this.checkDriver();

    if (this.isEnabled()) {
      this.driver!.setUser(user);
    }
  }

  /**
   * add context
   * @param contextName
   * @param context
   */
  setContext(contextName: string, context: Context) {
    this.checkDriver();

    if (this.isEnabled()) {
      this.driver!.setContext(contextName, context);
    }
  }

  /**
   * manually report an exception
   * Usage:
   * import sentry from 'src/services/sentry';
   * try {
   *     ...do some dangerous stuff
   * } catch(e) {
   *     Sentry.captureException(e);
   * }

   * OR

   * Sentry.captureException(Error("Error text", { someMoreData: { foo: 42 } }));
   * @param exception
   * @param extra
   */
  captureException(exception: object, extra?: object) {
    this.checkDriver();

    if (this.isEnabled()) {
      this.driver!.withScope((scope: Scope) => {
        if (extra) {
          scope.setExtras(extra);
        }
        this.driver!.captureException(exception);
      });
    }
  }

  /**
   * manually report a message
   * Usage:
   * import Sentry from 'src/services/sentry';
   * Sentry.captureMessage("Message text");
   * @param message
   * @param extra
   */
  captureMessage(message: string, extra?: object) {
    this.checkDriver();

    if (this.isEnabled()) {
      this.driver!.withScope((scope: Scope) => {
        if (extra) {
          scope.setExtras(extra);
        }
        this.driver!.captureMessage(message);
      });
    }
  }

  /**
   * set tag
   * @param key
   * @param value
   */
  setTag(key: string, value: unknown) {
    this.checkDriver();

    if (this.isEnabled()) {
      this.driver!.setTag(key, value);
    }
  }

  /**
   * set extra
   * @param key
   * @param value
   */
  setExtra(key: string, value: unknown) {
    this.checkDriver();

    if (this.isEnabled()) {
      this.driver?.setExtra(key, value);
    }
  }

  /**
   * add breadcrumb
   * @param breadcrumb
   */
  addBreadcrumb(breadcrumb: Breadcrumb) {
    this.checkDriver();

    if (this.isEnabled()) {
      this.driver!.addBreadcrumb(breadcrumb);
    }
  }

  /**
   *  generate native crash
   */
  nativeCrash() {
    if (this.isEnabled()) {
      this.driver!.nativeCrash?.();
    }
  }

  checkDriver() {
    if (!this.driver) {
      throw Error('Sentry driver was not initialized!');
    }

    ['init', 'setUser', 'setContext', 'captureException', 'captureMessage'].forEach((key) => {
      if (!(key in this.driver!)) {
        throw Error(`Sentry driver must have a ${key} method`);
      }
    });
  }
}

export default new Sentry();
