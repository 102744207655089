import { getEnv } from '../../config/environment';

export default function authenticateYouTube(
  userId: any,
  selectedPlatforms: string[],
  brandName: string
) {
  return new Promise((resolve, reject) => {
    const authUrl = getYouTubeAuthUrl(window.location, userId, selectedPlatforms, brandName);
    window.location.href = authUrl;
    resolve(true);
  });
}

const getYouTubeAuthUrl = (
  currentLocation: Location,
  userId: string | null,
  selectedPlatforms: string[],
  brandName: string
): string => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set('selectedPlatforms', selectedPlatforms.join(','));
  if (brandName) {
    urlParams.set('brand', brandName);
  }
  const serverCallbackUri = encodeURIComponent(getEnv().YOUTUBE_SERVER_CALLBACK_URI);
  const clientId = getEnv().YOUTUBE_CLIENT_ID;
  const scope = encodeURIComponent('https://www.googleapis.com/auth/youtube.readonly');
  const redirectUrlBase = `${getEnv().DOMAIN_HOST}${
    currentLocation.pathname
  }?${urlParams.toString()}`;
  const queryChar = redirectUrlBase.includes('?') ? '&' : '?';

  const failureRedirectUrl = `${redirectUrlBase}${queryChar}provider=youtube`;
  const successRedirectUrl =
    `${redirectUrlBase}/callback?success=true&provider=youtube&redirectPath=${currentLocation.pathname}`.replace(
      '//callback',
      '/callback'
    );

  const state = encodeURIComponent(
    JSON.stringify({
      success_redirect_url: successRedirectUrl,
      failure_redirect_url: failureRedirectUrl,
      user_id: userId
    })
  );

  return `https://accounts.google.com/o/oauth2/auth?response_type=code&include_granted_scopes=true&access_type=offline&approval_prompt=force&redirect_uri=${serverCallbackUri}&scope=${scope}&client_id=${clientId}&state=${state}`;
};
