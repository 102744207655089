import React, { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Icon, Body } from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import { INFO_BOX_STYLES, INFO_MESSAGES } from './constants/platformConstants';
import { useErrorHandling, usePlatformManagement, useFlowState } from './hooks/usePlatformSync';
import authenticateTiktok from '../../../services/tiktok_creator_market_place/TiktokCreatorMarketPlace';
import authenticateYouTube from '../../../services/youtube/Youtube';
import authenticateFacebook from '../../../services/facebook/Facebook';
import { Platform } from './types/platforms';
import UnfinishedFlow from './UnfinishedFlow';
import FinishedFlow from './FinishedFlow';
import InstagramInstructions from './InstagramInstructions';
import useUserQuery from '../../../hooks/queries/use-user-query';
import translate from '../../../utils/translate';
import styles from './SyncPlatforms.module.scss';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import { flowNames } from '../../../lib/delta/deltaConstants';
import DeltaHelper from '../../../services/delta/DeltaHelper';
import Delta from '../../../utils/wrappers/Delta';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import SyncPlatformsContent from './SyncPlatformsContent';
import SIGNUP_STEP_STARTED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupStepStarted';
import SIGNUP_STEP_ENDED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupStepEnded';
import SIGNUP_FLOW_ENDED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupFlowEnded';

export type SyncPlatformsProps = {
  selectedPlatforms: Platform[];
  handleFlowTransition: () => void;
};

function SyncPlatforms(props: SyncPlatformsProps) {
  const { selectedPlatforms, handleFlowTransition } = props;
  const { user: userData } = useUserQuery({});
  const userIdentities = userData?.identities || [];
  const totalPlatforms =
    selectedPlatforms.filter((platform) => platform !== 'Facebook' && platform !== 'YouTube')
      .length + 1;
  const [searchParams] = useSearchParams();
  const brandName = searchParams.get('brand') || '';
  const signupStepsProcessIds = useRef<Record<string, string>>({
    TikTok: '',
    YouTube: '',
    Facebook: ''
  });

  const { error, setError, isClosingErrorBox, handleCloseErrorBox } = useErrorHandling();

  const {
    connectedPlatforms,
    filteredConnectedPlatforms,
    avatarUrl,
    loadingPlatform,
    setLoadingPlatform
  } = usePlatformManagement(selectedPlatforms, userIdentities);

  const lastFilteredConnectedPlatforms = useRef<Platform[]>(filteredConnectedPlatforms);

  useEffect(() => {
    const addedPlatform = filteredConnectedPlatforms.find(
      (platform) => !lastFilteredConnectedPlatforms.current.includes(platform)
    );
    if (addedPlatform) {
      const processId = signupStepsProcessIds.current[addedPlatform];
      Delta.sendEvent(SIGNUP_STEP_ENDED_SCHEMA.name, {
        flow_id: flow.current.flow_id,
        process_id: processId,
        signup_provider: addedPlatform,
        request_type: 'connect platform',
        reason: 'success'
      });
      lastFilteredConnectedPlatforms.current = filteredConnectedPlatforms;
      signupStepsProcessIds.current[addedPlatform] = '';
    }
  }, [filteredConnectedPlatforms]);

  const {
    isUnfinishedFlow,
    setIsUnfinishedFlow,
    isFinishedFlow,
    showInstagramInstructions,
    setShowInstagramInstructions
  } = useFlowState(filteredConnectedPlatforms, totalPlatforms);

  const progressPercentage = useMemo(
    () => (filteredConnectedPlatforms.length / totalPlatforms) * 100,
    [filteredConnectedPlatforms.length, totalPlatforms]
  );

  const flowNotCompleted = useMemo(
    () => filteredConnectedPlatforms.length < totalPlatforms,
    [filteredConnectedPlatforms.length, totalPlatforms]
  );

  const { infoMessage, infoIcon } = useMemo(() => {
    const remainingPlatforms = selectedPlatforms.filter(
      (platform) => !filteredConnectedPlatforms.includes(platform)
    );
    const info =
      INFO_MESSAGES.find(({ count }) => count === filteredConnectedPlatforms.length) ||
      INFO_MESSAGES[0];

    let message = translate(info.message);
    if (info.count === 3 && remainingPlatforms) {
      message = translate(info.message, { social: remainingPlatforms });
    }

    return {
      infoMessage: message,
      infoIcon: info.icon
    };
  }, [filteredConnectedPlatforms, selectedPlatforms]);

  const flow = useRef(DeltaHelper.getOrCreateFlow(flowNames.brandsSafetyConnectPlatforms));
  const screenId = useRef(uuid());
  const sendDismissedEventRef = useRef<(flowId: string, screenId: string) => void>();
  useEffect(() => {
    sendDismissedEventRef.current = (flowId: string, inputScreenId: string) => {
      Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
        flow_id: flowId,
        screen_presentation_id: inputScreenId,
        signup_screen_name: 'Sync Platforms',
        presented_details: JSON.stringify({
          selectedPlatforms,
          filteredConnectedPlatforms,
          loadingPlatform
        })
      });
    };
  }, [selectedPlatforms, filteredConnectedPlatforms, loadingPlatform]);
  useEffect(() => {
    Delta.sendEvent(SIGNUP_SCREEN_PRESENTED_SCHEMA.name, {
      flow_id: flow.current.flow_id,
      screen_presentation_id: screenId.current,
      signup_screen_name: 'Sync Platforms',
      presented_details: JSON.stringify({
        selectedPlatforms,
        filteredConnectedPlatforms,
        loadingPlatform
      })
    });
    return () => {
      if (sendDismissedEventRef.current) {
        sendDismissedEventRef.current(flow.current.flow_id, screenId.current);
      }
    };
  }, []);

  const handleConnectPlatform = async (platform: string) => {
    const processId = uuid();
    signupStepsProcessIds.current[platform] = processId;
    Delta.sendEvent(SIGNUP_STEP_STARTED_SCHEMA.name, {
      flow_id: flow.current.flow_id,
      process_id: processId,
      signup_provider: platform,
      request_type: 'connect platform'
    });
    if (platform === 'Instagram') {
      setIsUnfinishedFlow(false);
      setShowInstagramInstructions(true);
      return;
    }
    try {
      switch (platform) {
        case 'TikTok':
          signupStepsProcessIds.current.TikTok = processId;
          await authenticateTiktok(userData?.id, selectedPlatforms, brandName);
          break;
        case 'YouTube':
          signupStepsProcessIds.current.YouTube = processId;
          await authenticateYouTube(userData?.id, selectedPlatforms, brandName);
          break;
        case 'Facebook':
          signupStepsProcessIds.current.Facebook = processId;
          await authenticateFacebook(userData?.id, selectedPlatforms, brandName);
          break;
        default:
          throw new Error(`Platform ${platform} is not supported.`);
      }
    } catch (err) {
      signupStepsProcessIds.current[platform] = '';
      setError(`Failed to connect to ${platform}. Please try again.`);
      Delta.sendEvent(SIGNUP_STEP_ENDED_SCHEMA.name, {
        flow_id: flow.current.flow_id,
        process_id: processId,
        signup_provider: platform,
        request_type: 'connect platform',
        reason: 'failure',
        error: JSON.stringify(err)
      });
    } finally {
      setLoadingPlatform(null);
    }
  };

  const handleFinishConnecting = () => {
    const missingPlatforms = selectedPlatforms
      .filter((platform) => platform !== 'Facebook')
      .filter((platform) => !filteredConnectedPlatforms.includes(platform));

    if (missingPlatforms.length === 1) {
      handleConnectPlatform(missingPlatforms[0]);
      return;
    }
    setIsUnfinishedFlow(false);
  };

  const handleUnfinishedFlow = () => flowNotCompleted && setIsUnfinishedFlow(true);

  const renderErrorBox = () => (
    <div className={`${styles.errorBox} ${isClosingErrorBox ? styles.hide : ''}`}>
      <div
        className={styles.errorBoxCloseButton}
        onClick={handleCloseErrorBox}
        role="button"
        tabIndex={0}>
        <Icon name="Actions-Close-Small" size="large" appearance="secondary" />
      </div>
      <Icon name="Actions-Patch-Cancel-Line" size="medium" appearance="danger" />
      <Body size="md" className={styles.errorBoxMessage}>
        {error}
      </Body>
    </div>
  );

  const renderInfoBox = () => {
    const infoBoxStyle =
      INFO_BOX_STYLES[connectedPlatforms.length as keyof typeof INFO_BOX_STYLES] ||
      INFO_BOX_STYLES.default;

    return (
      <div className={`${styles.infoBox} ${infoBoxStyle}`}>
        <Icon name={infoIcon} size="large" appearance="primary" />
        <p>{infoMessage}</p>
      </div>
    );
  };

  if (isFinishedFlow) {
    Delta.sendEvent(SIGNUP_FLOW_ENDED_SCHEMA.name, {
      flow_id: flow.current.flow_id,
      flow_name: flow.current.flow_name,
      reason: 'success'
    });
    return <FinishedFlow />;
  }

  if (isUnfinishedFlow) {
    const missingPlatforms = selectedPlatforms
      .filter((platform) => platform !== 'Facebook')
      .filter((platform) => !filteredConnectedPlatforms.includes(platform));

    return (
      <UnfinishedFlow
        handleFinishConnecting={handleFinishConnecting}
        missingPlatform={missingPlatforms.length === 1 ? missingPlatforms[0] : undefined}
        handleBackToSelect={handleFlowTransition}
      />
    );
  }

  if (showInstagramInstructions) {
    return (
      <InstagramInstructions
        handleBack={() => setShowInstagramInstructions(false)}
        handleUnfinishedFlow={handleUnfinishedFlow}
        userId={userData?.id}
        selectedPlatforms={selectedPlatforms}
        handleContinueWithFacebook={handleFinishConnecting}
      />
    );
  }

  return (
    <SyncPlatformsContent
      handleFlowTransition={handleFlowTransition}
      handleUnfinishedFlow={handleUnfinishedFlow}
      flowNotCompleted={flowNotCompleted}
      progressPercentage={progressPercentage}
      avatarUrl={avatarUrl}
      error={error}
      renderErrorBox={renderErrorBox}
      renderInfoBox={renderInfoBox}
      PlatformListProps={{
        selectedPlatforms,
        connectedPlatforms,
        loadingPlatform,
        onConnectPlatform: handleConnectPlatform
      }}
    />
  );
}

export default SyncPlatforms;
