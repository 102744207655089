import { ApiClient, IConfig, IResult, QueryArg } from 'jsonapi-react';
import { snakeCase } from 'lodash';
import { tokenSerializer } from './token';
import mapKeysToCase from './mapKeysToCase';
import getCircularReplacer from './getCircularReplacer';
import { getEnv } from '../config/environment';
import { BASE_CONFIG } from '../config/http';
import schema from '../schema';

async function fetchWithToken(path: string, config: RequestInit) {
  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...BASE_CONFIG.headers,
      ...(await tokenSerializer())
    }
  };

  return fetch(path, newConfig);
}

const jsonApiClient = new ApiClient({
  url: getEnv().VITE_API_HOST || 'http://api.test.popularpays.com:3000',
  schema,
  cacheTime: getEnv().MODE === 'test' ? 0 : 5 * 60,
  staleTime: 60,
  fetch: fetchWithToken
});

export async function jsonApiFetch(queryArg: QueryArg, config = {}) {
  const results = await jsonApiClient.fetch(queryArg, config);
  // remove circular references from the returned data + map the results data keys to camelCase
  return JSON.parse(JSON.stringify(mapKeysToCase(results), getCircularReplacer()));
}

export async function jsonApiMutate(queryArg: QueryArg, data: any, config = {}) {
  const serializedData: any = mapKeysToCase(data, snakeCase);
  const results = await jsonApiClient.mutate(queryArg, serializedData, config);
  return mapKeysToCase(results);
}

export async function jsonApiDelete(queryArg: QueryArg, config = {}) {
  const results = await jsonApiClient.delete(queryArg, config);
  return JSON.parse(JSON.stringify(mapKeysToCase(results), getCircularReplacer()));
}

export default jsonApiClient;
