import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useState, useEffect } from 'react';
import { getEnv } from '../../config/environment';
import getCookieValue from '../../utils/getCookieValue';
import useUserQuery from '../queries/use-user-query';
import useToken from '../use-token';

function useSetLaunchDarklyContext() {
  const { isAuthenticated, initialized: tokenInitialized } = useToken(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const flags = useFlags();
  const ldClient = useLDClient();
  const ltId = getCookieValue(`cwa_${getEnv().MODE}__lt_id`);

  const { user, isLoading: isLoadingUser } = useUserQuery({
    overrideEnabled: isAuthenticated,
    include: []
  });
  useEffect(() => {
    if (!ldClient) {
      return;
    }

    if (
      isAuthenticated &&
      tokenInitialized &&
      user?.email &&
      ltId &&
      ldClient?.getContext()?.key !== ltId
    ) {
      let name;
      if (!user?.firstName && !user?.lastName) {
        name = user?.email;
      } else {
        name = `${user?.firstName} ${user?.lastName}`;
      }

      ldClient
        .identify({
          key: ltId,
          kind: 'cwa-user',
          name,
          first_name: user?.firstName,
          last_name: user?.lastName,
          email: user?.email,
          ip: user?.currentSignInIp
        })
        .then(() => {
          setIsInitialized(true);
        });
    } else if (!isAuthenticated && ldClient?.getContext()?.key !== 'anonymous-user') {
      ldClient.identify({ key: 'anonymous-user', anonymous: true });
    }

    if (tokenInitialized && !isAuthenticated && !isInitialized) {
      setIsInitialized(true);
    }
  }, [isAuthenticated, tokenInitialized, user, isInitialized, ltId, ldClient]);

  return {
    flags,
    launchDarklyInitialized: isInitialized
  };
}

export default useSetLaunchDarklyContext;
