import { getEnv } from '../../config/environment';

export default function authenticateFacebook(
  userId: any,
  selectedPlatforms: string[],
  brandName: string
) {
  return new Promise((resolve, reject) => {
    const authUrl = getFacebookAuthUrl(userId, selectedPlatforms, brandName);
    window.location.href = authUrl;
  });
}

const getFacebookAuthUrl = (
  userId: string | null,
  selectedPlatforms: string[],
  brandName: string
): string => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set('selectedPlatforms', selectedPlatforms.join(','));
  if (brandName) {
    urlParams.set('brand', brandName);
  }
  const facebookAppId = getEnv().FACEBOOK_APP_ID;
  const redirectUrlBase = `${getEnv().DOMAIN_HOST}${window.location.pathname}`;
  const serverCallbackUri = getEnv().META_SERVER_CALLBACK_URI;
  const successRedirectUrl =
    `${redirectUrlBase}/callback?success=true&provider=facebook&redirectPath=${
      window.location.pathname
    }&${urlParams.toString()}`.replace('//callback', '/callback');
  const queryChar = redirectUrlBase.includes('?') ? '&' : '?';
  const failureRedirectUrl = `${redirectUrlBase}${queryChar}provider=facebook`;

  const state = {
    network: 'facebook',
    success_redirect_url: successRedirectUrl,
    failure_redirect_url: failureRedirectUrl,
    user_id: userId,
    redirect_uri: serverCallbackUri
  };

  const scopes = [
    'business_management',
    'pages_read_user_content',
    'pages_manage_metadata',
    'pages_read_engagement'
  ].join(',');

  const authUrl = new URL('https://www.facebook.com/v16.0/dialog/oauth');
  authUrl.searchParams.set('client_id', facebookAppId);
  authUrl.searchParams.set('redirect_uri', serverCallbackUri);
  authUrl.searchParams.set('response_type', 'code,granted_scopes');
  authUrl.searchParams.set('scope', scopes);
  authUrl.searchParams.set('state', encodeURIComponent(JSON.stringify(state)));

  return authUrl.toString();
};
