import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonMuiProps } from './ButtonMuiProps';

function ButtonMui(props: ButtonMuiProps) {
  const { testID, type, link, onClick, children, ...rest } = props;

  const LinkStyleButton = styled(Button)({
    backgroundColor: 'transparent',
    color: '#404040',
    textDecoration: 'underline',
    borderRadius: '3px',
    textTransform: 'capitalize',
    fontWeight: '300',
    padding: '9px 0px 7px 0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  });

  const FilledStyledButton = styled(Button)`
    background-color: #404040;
    color: #fff;
    border-radius: 3px;
    text-transform: capitalize;
    font-weight: 300;
    padding: 9px 24px 7px 24px;
    &:hover {
      background-color: #404040;
    }
  `;

  if (type === 'filled') {
    return (
      <FilledStyledButton {...rest} onClick={onClick} href={link}>
        {children}
      </FilledStyledButton>
    );
  }

  return (
    <LinkStyleButton {...rest} onClick={onClick} href={link}>
      {children}
    </LinkStyleButton>
  );
}

export default ButtonMui;
