import React from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ModalTitleProps } from './ModalTitleProps';
import { CloseFilterModalIcon, FiltersTuneIcon } from '../../icons';
import styles from './ModalTitle.module.scss';

const StyledStack = styled(Stack)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center'
});

function ModalTitle(props: ModalTitleProps) {
  const { onCloseButtonClick, title } = props;
  return (
    <StyledStack spacing={2} className={styles.root}>
      <div
        style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '8px' }}>
        <FiltersTuneIcon width={18} height={15} />
        <span className={styles.title}>{title}</span>
      </div>
      <div
        id="close_icon"
        role="presentation"
        className={styles.close}
        style={{ marginTop: '0' }}
        onClick={onCloseButtonClick}
        onKeyDown={onCloseButtonClick}>
        <CloseFilterModalIcon />
      </div>
    </StyledStack>
  );
}

export default ModalTitle;
