import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { queryClient } from './reactQueryClient';
import Storage from './wrappers/Storage';

export interface AuthenticatedToken {
  authenticator?: string;
  client?: string;
  email?: string;
  token?: string;
}

export interface Token {
  authenticated?: AuthenticatedToken;
}

export const STORAGE_TOKEN_KEY = 'react_simple_auth-session';

export const getToken = async (): Promise<Token | undefined> => {
  const token = await Storage.getItem(STORAGE_TOKEN_KEY);
  if (!token) {
    return undefined;
  }
  return token;
};

export const getSessionDataFromHeaders = (
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
) => {
  const { client, uid, 'access-token': token } = headers;
  return {
    authenticated: {
      authenticator: 'authenticator:devise',
      client,
      email: uid,
      token
    }
  };
};

export const getTokenSync = (): Token | undefined => {
  const token = Storage.getItemSync(STORAGE_TOKEN_KEY);
  if (!token) {
    return undefined;
  }
  return token;
};

export const getSerializedToken = (token: Token) => {
  const { authenticated } = token as { authenticated: AuthenticatedToken };
  return {
    uid: authenticated.email,
    'access-token': authenticated.token,
    client: authenticated.client
  };
};

export const isAuthenticated = (token: Token | undefined) => {
  if (token?.authenticated) {
    const { authenticated } = token;
    return !!(authenticated?.email && authenticated?.token && authenticated?.client);
  }

  return false;
};

export const updateToken = async (newAuthToken: string | null) => {
  const token = await getToken();
  if (!token?.authenticated || !newAuthToken || token.authenticated.token === newAuthToken) {
    return false;
  }

  token.authenticated.token = newAuthToken;
  await Storage.setItem(STORAGE_TOKEN_KEY, token);
  queryClient.cancelQueries();
  return true;
};

export const tokenSerializer = async () => {
  const token = await getToken();

  if (isAuthenticated(token)) {
    return getSerializedToken(token as Token);
  }
  return null;
};
