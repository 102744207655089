import { configure } from 'mobx';
import RootStore from './RootStore';

// enforce to never use Proxies on WEB development mode only
configure({
  enforceActions: 'observed'
  // useProxies: __DEV__ ? 'never' : undefined
});
let rootStoreInstance: RootStore | null;

export const rootStore = () => {
  if (!rootStoreInstance) {
    rootStoreInstance = new RootStore();
  }
  return rootStoreInstance;
};

// Only for testing purposes
export const resetRootStore = () => {
  rootStoreInstance = null;
};
