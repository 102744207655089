import { makeAutoObservable } from 'mobx';
import FlashMessageStore from './FlashMessageStore';
import FilterPopupParametersStore from './FilterPopupParametersStore';

type RootStoreInitialState = {
  redirectUrlAfterAuth: string | null;
};

const rootStoreInitialState: RootStoreInitialState = {
  redirectUrlAfterAuth: null
};

class RootStore {
  store = rootStoreInitialState;

  flashMessageStore: FlashMessageStore;

  filterPopupParametersStore: FilterPopupParametersStore;

  constructor() {
    makeAutoObservable(this);
    this.flashMessageStore = new FlashMessageStore(this);
    this.filterPopupParametersStore = new FilterPopupParametersStore(this);
  }

  setRedirectUrlAfterAuth = (redirectUrlAfterAuth: string | null) => {
    this.store.redirectUrlAfterAuth = redirectUrlAfterAuth;
  };

  get redirectUrlAfterAuth() {
    return this.store.redirectUrlAfterAuth;
  }
}

export default RootStore;
