import { mediaBreakpoints } from '@lightricks/react-design-system';

const root = document.documentElement;

const initMediaBreakPoints = () => {
  Object.keys(mediaBreakpoints).forEach((key) => {
    root.style.setProperty(`--${key}`, `${mediaBreakpoints[key]}px`);
  });
};

initMediaBreakPoints();
