import styles from '../SyncPlatforms.module.scss';

export const INFO_MESSAGES = [
  {
    count: 1,
    message: 'components.brand-safety.sign-up.sync-platforms.default',
    icon: 'Navigation-Shopbag-Shield-Stroke' as const
  },
  {
    count: 2,
    message: 'components.brand-safety.sign-up.sync-platforms.two-platforms',
    icon: 'Actions-Trending' as const
  },
  {
    count: 3,
    message: 'components.brand-safety.sign-up.sync-platforms.three-platforms',
    icon: 'Navigation-Stats' as const
  }
] as const;

export const INFO_BOX_STYLES = {
  1: styles.infoBoxPurple,
  2: styles.infoBoxBlue,
  default: styles.infoBoxGreen
} as const;
