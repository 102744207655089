import React from 'react';
import { Provider as MobxProvider } from 'mobx-react';
import { LDProvider, basicLogger } from 'launchdarkly-react-client-sdk';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ApiProvider } from 'jsonapi-react';
import { I18nextProvider } from 'react-i18next';
import { capitalize } from 'lodash';
import { DesignSystemThemeProvider } from '@lightricks/react-design-system';
import { rootStore } from './stores';
import i18n from './config/locales';
import { getEnv } from './config/environment';
import jsonApiClient from './utils/jsonapiClient';
import { queryClient, asyncStoragePersister } from './utils/reactQueryClient';

function AppProviders({
  children,
  newJsonApiClient
}: {
  children: React.ReactNode;
  newJsonApiClient?: typeof jsonApiClient;
}) {
  return (
    <LDProvider
      clientSideID={getEnv().VITE_LAUNCHDARKLY_CLIENTSIDE_ID}
      context={{ key: 'anonymous-user', anonymous: true }}
      reactOptions={{ useCamelCaseFlagKeys: true }}
      options={{
        application: {
          id: `CreatorsWebApp-React-${capitalize(getEnv().MODE)}`,
          version: '1.0.0'
        },
        bootstrap: 'localStorage',
        logger: basicLogger({
          level: getEnv().MODE === 'test' ? 'none' : 'error'
        })
      }}>
      <ApiProvider client={newJsonApiClient || jsonApiClient}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister: asyncStoragePersister }}>
          <I18nextProvider i18n={i18n}>
            <MobxProvider root={rootStore()} {...rootStore()}>
              <DesignSystemThemeProvider theme="popularpayLight">
                {children}
              </DesignSystemThemeProvider>
            </MobxProvider>
          </I18nextProvider>
        </PersistQueryClientProvider>
      </ApiProvider>
    </LDProvider>
  );
}

export default AppProviders;
