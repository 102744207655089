import { useParams as useParamsReactRouterDOM } from 'react-router-dom';
import getUrlParams from '../../utils/getUrlParams';

function useParams(): any {
  const params = useParamsReactRouterDOM();
  const queryParams = getUrlParams();

  return {
    ...params,
    ...queryParams
  };
}

export default useParams;
