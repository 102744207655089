import {
  FilterPopupParametersStoreInitialState,
  RangeSliderValues,
  SectionCompensationType,
  SectionDeliverable,
  SingleQueryParamsObject,
  SingleTag
} from './FilterPopupParametersType';

const currentUrlParams = new URLSearchParams(window.location.search);
export const sectionDeliverableZero: SectionDeliverable = {
  photos: false,
  videos: false
};
const sectionSocialFollowersRangeZero: RangeSliderValues = {
  curMin: 0,
  curMax: 100000,
  min: 0,
  max: 100000
};

const sectionCompensationTypeZero: SectionCompensationType = {
  fixed: false,
  negotiable: false
};

const sectionCompensationSumZero: RangeSliderValues = {
  curMin: 0,
  curMax: 5000,
  min: 0,
  max: 5000
};

const sectionTagsZero: string[] = [];
const sectionNetworksZero: string[] = [];
export const SectionQueryParamsListZero: SingleQueryParamsObject[] = [];

export const filterPopupParametersStoreInitialStateZero: FilterPopupParametersStoreInitialState = {
  filterPopupParameters: {
    deliverables: sectionDeliverableZero,
    socialNetworks: sectionNetworksZero,
    socialFollowersRange: sectionSocialFollowersRangeZero,
    compensationType: sectionCompensationTypeZero,
    compensationSum: sectionCompensationSumZero,
    tags: sectionTagsZero,
    currentQueryParamsList: SectionQueryParamsListZero,
    currentQueryParamsUrl: '',
    isDirty: false
  }
};
