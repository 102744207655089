import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import EmberIframe from '../../ember-iframe';
import styles from './MainManu.module.scss';
import MenuItem from '../menu-item';
import { MenuProps } from '../MenuProps';
import translate from '../../../utils/translate';
import { Folder, Icon, InfiniteCircleArrowsIcon, LightningIcon, User } from '../../icons';
import useUserQuery from '../../../hooks/queries/use-user-query';
import useUserCollaborationsQuery from '../../../hooks/queries/use-user-collaborations-query';

type MainMenuLink = {
  id: string;
  titleLocaleKey: string;
  path: string;
  icon?: Icon;
};

export const mainMenuLinks: MainMenuLink[] = [
  {
    id: 'suggeested-gigs',
    titleLocaleKey: 'components.user-toolbar.latest-gigs',
    path: '',
    icon: Folder
  },
  {
    id: 'your-gigs',
    titleLocaleKey: 'components.user-toolbar.your-gigs',
    path: 'gigs',
    icon: LightningIcon
  },
  {
    id: 'threads',
    titleLocaleKey: 'components.user-toolbar.threads',
    path: 'collaborations',
    icon: InfiniteCircleArrowsIcon
  },
  {
    id: 'account',
    titleLocaleKey: 'components.user-toolbar.account',
    path: 'account',
    icon: User
  }
];

function MainMenu(props: MenuProps) {
  const { trackEventClickMenuItem } = props;
  const [hasUnreadCollaborations, setHasUnreadCollaborations] = useState(false);
  const [hasUnreadPaidGigConversationMessages, setHasUnreadPaidGigConversationMessages] =
    useState(false);

  function mutateConversationStates(
    userHasUnreadCollaborations: boolean,
    userHasUnreadPaidGigConversationMessages: boolean
  ) {
    setHasUnreadCollaborations(userHasUnreadCollaborations);
    setHasUnreadPaidGigConversationMessages(userHasUnreadPaidGigConversationMessages);
  }

  const { user: userData, isLoading: isLoadingUser } = useUserQuery({});
  const { data: collaborationsData } = useUserCollaborationsQuery();

  useEffect(() => {
    const removeMarkConversationAsReadListener = EmberIframe.listen(
      'markConversationAsRead',
      (data: {
        hasUnreadCollaborations: boolean;
        hasUnreadPaidGigConversationMessages: boolean;
      }) => {
        mutateConversationStates(
          data.hasUnreadCollaborations,
          data.hasUnreadPaidGigConversationMessages
        );
      }
    );
    if (!isLoadingUser && userData) {
      mutateConversationStates(
        userData.hasUnreadCollaborations,
        userData.hasUnreadPaidGigConversationMessages
      );
    }

    return removeMarkConversationAsReadListener;
  }, [userData]);

  return (
    <div className={styles.mainMenu}>
      {mainMenuLinks.map((menuLink: MainMenuLink) => {
        const { id, path, titleLocaleKey, icon } = menuLink;

        const title = translate(titleLocaleKey);

        if (path === 'collaborations' && (!collaborationsData || collaborationsData.length === 0)) {
          return null;
        }

        return (
          <NavLink
            className={styles.menuLink}
            key={title}
            to={path}
            title={title}
            onClick={() => trackEventClickMenuItem(title, path, false)}>
            {({ isActive }) => (
              <MenuItem
                id={id}
                title={title}
                isActive={isActive}
                icon={icon}
                hasUnreadNotification={
                  (hasUnreadCollaborations && path === 'collaborations') ||
                  (hasUnreadPaidGigConversationMessages && path === 'gigs')
                }
              />
            )}
          </NavLink>
        );
      })}
    </div>
  );
}

export default MainMenu;
