import React from 'react';
import OtpVerificationModal from '../../../../components/otp-verification-modal';

interface OtpVerificationProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  email: string;
  onSuccess: (otp: string) => void;
  onGoBack: () => void;
}

function OtpVerification(props: OtpVerificationProps) {
  const { isOpen, setIsOpen, email, onSuccess, onGoBack } = props;

  return (
    <OtpVerificationModal
      isOpen={isOpen}
      setIsModalOpen={setIsOpen}
      email={email}
      authenticateCallback={onSuccess}
      authFlowType="brand_safety_signup"
      handleBackToEmail={onGoBack}
    />
  );
}

export default OtpVerification;
