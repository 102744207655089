import {
  FilterPopupParametersStoreInitialState,
  RangeSliderValues,
  SectionCompensationType,
  SectionDeliverable,
  SingleQueryParamsObject,
  SingleTag
} from './FilterPopupParametersType';

const currentUrlParams = new URLSearchParams(window.location.search);
export const sectionDeliverable: SectionDeliverable = {
  photos: currentUrlParams.get('deliverable_type')?.includes('photos') || false,
  videos: currentUrlParams.get('deliverable_type')?.includes('videos') || false
};
const followers_min = currentUrlParams.get('followers_min') || '0';
const followers_max = currentUrlParams.get('followers_max') || '100000';
const sectionSocialFollowersRange: RangeSliderValues = {
  curMin: parseInt(followers_min, 10),
  curMax: parseInt(followers_max, 10),
  min: 0,
  max: 100000
};

const sectionCompensationType: SectionCompensationType = {
  fixed: currentUrlParams.get('compensation_type')?.includes('fixed') || false,
  negotiable: currentUrlParams.get('compensation_type')?.includes('negotiable') || false
};

const price_min = currentUrlParams.get('price_min') || '0';
const price_max = currentUrlParams.get('price_max') || '5000';
const sectionCompensationSum: RangeSliderValues = {
  curMin: parseInt(price_min, 10),
  curMax: parseInt(price_max, 10),
  min: 0,
  max: 5000
};

const sectionTags: string[] = currentUrlParams.get('tags')?.split(',') || [];
const sectionNetworks: string[] = currentUrlParams.get('network')?.split(',') || [];
export const SectionQueryParamsList: SingleQueryParamsObject[] = [];

export const filterPopupParametersStoreInitialState: FilterPopupParametersStoreInitialState = {
  filterPopupParameters: {
    deliverables: sectionDeliverable,
    socialNetworks: sectionNetworks,
    socialFollowersRange: sectionSocialFollowersRange,
    compensationType: sectionCompensationType,
    compensationSum: sectionCompensationSum,
    tags: sectionTags,
    currentQueryParamsList: SectionQueryParamsList,
    currentQueryParamsUrl: '',
    isDirty: false
  }
};
