import './_style.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { LinkButtonProps } from './LinkButtonProps';

type ToProps = {
  url: string;
};

function LinkButton(props: LinkButtonProps & ToProps) {
  const { to, children, onClick, testID, className, ...rest } = props;

  const testIDProps = {
    'data-testid': testID || undefined
  };

  return (
    <Link
      className={`${className} link-button`}
      to={to.url}
      onClick={onClick}
      {...testIDProps}
      {...rest}>
      {children}
    </Link>
  );
}

export default LinkButton;
