import React from 'react';
import { Route } from 'react-router-dom';
import EmberIframe from '../../components/ember-iframe/EmberIframe';
import EmberCampaign from '../../components/ember-campaign';
import RedirectFromLtx from '../../components/redirect-from-ltx';

function PublicAccessRoutes() {
  return [
    <Route
      key="campaign-invite-route"
      path="/invite/campaign/:campaignId"
      element={<EmberCampaign />}
    />,
    <Route
      key="campaign-preview-route"
      path="/preview/campaign/:campaignId"
      element={<EmberIframe isFullHeight />}
    />,
    <Route
      key="auth-redirect-from-ltx-route"
      path="/auth/redirect-from-ltx"
      element={<RedirectFromLtx />}
    />
  ];
}

export default PublicAccessRoutes;
