import { useEffect, useState } from 'react';
import Storage from '../../utils/wrappers/Storage';
import Fortress from '../../services/fortress/Fortress';

const useLtxToken = (subscribeToTokenChange = false) => {
  const [ltxTokenInitialized, setLtxTokenInitialized] = useState(false);
  const [ltxToken, setLtxToken] = useState<string | null | undefined>(null);

  useEffect(() => {
    (async () => {
      const userLtxToken = await Fortress.getAccessToken();
      setLtxToken(userLtxToken);
      setLtxTokenInitialized(true);
      if (subscribeToTokenChange) {
        Fortress.addSubscriber(Fortress.SAVE_FORTRESS_COOKIES, async () => {
          const newLtxToken = await Fortress.getAccessToken();
          setLtxToken(newLtxToken);
        });
      }
    })();

    return () => {
      if (subscribeToTokenChange) {
        Storage.removeSubscriber(Fortress.SAVE_FORTRESS_COOKIES);
      }
    };
  }, []);

  return {
    ltxToken,
    setLtxToken,
    ltxTokenInitialized
  };
};

export default useLtxToken;
