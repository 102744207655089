import { useQuery } from '@tanstack/react-query';
import userFetchers from '../../../api/fetchers/user';

const useUserCollaborationsQuery = () => {
  const results = useQuery({
    queryKey: ['user/collaborations'],
    queryFn: userFetchers.collaborations
  });
  return results;
};

export default useUserCollaborationsQuery;
