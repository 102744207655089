import { useNavigate } from 'react-router-dom';

// TODO: make improvements
//  https://reactrouter.com/en/main/hooks/use-navigate
function useNavigation() {
  const navigateProxy = useNavigate();

  const navigate = (to: { screen: string; url: string }, params?: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return navigateProxy(to.url, params);
  };

  const goBack = () => {
    return navigateProxy(-1);
  };

  return { navigate, goBack };
}

export default useNavigation;
