import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import EmberIframe from '../ember-iframe';
import useNavigation from '../../hooks/use-navigation';
import getUrlParams from '../../utils/getUrlParams';
import translate from '../../utils/translate';
import useToken from '../../hooks/use-token';

function RedirectFromLtx() {
  const { setToken } = useToken();
  const navigation = useNavigation();
  const params = getUrlParams();

  const [{ client_poppays, token_poppays, uid_poppays }] = useCookies([
    'client_poppays',
    'token_poppays',
    'uid_poppays'
  ]);

  useEffect(() => {
    (async () => {
      let shouldRedirectToLogin = false;
      if (client_poppays && token_poppays && uid_poppays) {
        const sessionData = {
          authenticated: {
            authenticator: 'authenticator:poppays-from-token',
            token: token_poppays,
            uid: uid_poppays,
            client: client_poppays
          }
        };
        const tokenSaved = await setToken(sessionData);
        if (!tokenSaved) {
          shouldRedirectToLogin = true;
        } else {
          EmberIframe.send('token', sessionData);
          setTimeout(() => {
            navigation.navigate({
              screen: '',
              url: `/?showRegistrationModal=${params.show_tos_modal || 'false'}`
            });
          });
        }
      } else {
        shouldRedirectToLogin = true;
      }

      if (shouldRedirectToLogin) {
        navigation.navigate({
          screen: '',
          url: `/auth/login?toast_message=${translate('templates.auth.login.errors.suspended')}`
        });
      }
    })();
  }, []);

  return null;
}

export default RedirectFromLtx;
