import React from 'react';
import { Stack as MuiStack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StackProps } from './StackProps';

const StyledStack = styled(MuiStack)({
  '&.MuiStack-root': {
    '@media (max-width: 780px)': {
      width: '88%'
    }
  }
});

function Stack(props: StackProps) {
  const { children, ...rest } = props;
  return <StyledStack {...props}>{children}</StyledStack>;
}

export default Stack;
