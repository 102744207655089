import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import useLtxToken from '../use-ltx-token';
import useAuthenticate from '../use-authenticate';
import useLogout from '../use-logout';
import useToken from '../use-token';
import useParams from '../use-params/useParams';

type TokenParse = {
  email: string;
};

const useTokenSessionInvalidated = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { token, initialized } = useToken();
  const { ltxToken, ltxTokenInitialized } = useLtxToken();
  const { onAuthenticate } = useAuthenticate();
  const { logout } = useLogout();
  const params = useParams();

  useEffect(() => {
    (async () => {
      if (initialized && ltxTokenInitialized && !token && ltxToken) {
        const { email } = jwt_decode<TokenParse>(ltxToken);
        const data = {
          ...params,
          email,
          password: ltxToken
        };
        setIsLoading(true);
        const isAuthenticateSuccess = await onAuthenticate(data, onError);
        setIsLoading(false);
        if (!isAuthenticateSuccess) {
          logout();
        }
      }
    })();
  }, [initialized, ltxTokenInitialized]);

  const onError = (error: string) => {
    logout();
  };

  return {
    isLoading
  };
};

export default useTokenSessionInvalidated;
