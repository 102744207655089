import React from 'react';
import { Body } from '@lightricks/react-design-system';
import styles from './AppFooter.module.scss';
import translate from '../../utils/translate';
import isMobile from '../../utils/identifyDevice';

function AppFooter() {
  const isMobileDevice = isMobile();
  return (
    <div className={styles.footer}>
      <Body size={isMobileDevice ? 'lg' : 'sm'}>
        <a
          className={styles.footerLink}
          href="http://popularpays.com/privacy"
          target="_blank"
          rel="noopener noreferrer">
          {translate('components.x-footer.privacy-policy-link')}
        </a>
      </Body>
      <Body size={isMobileDevice ? 'lg' : 'sm'}>
        <a
          className={styles.footerLink}
          target="_blank"
          rel="noreferrer"
          href="https://popularpays.zendesk.com/hc/en-us">
          {translate('components.x-footer.get-help-link')}
        </a>
      </Body>
    </div>
  );
}

export default AppFooter;
