import React from 'react';
import styles from './ProgressBar.module.scss';

export type ProgressBarProps = {
  value: number;
  label?: string;
};

function ProgressBar({ value, label }: ProgressBarProps) {
  return (
    <div className={styles.progressBarContainer} aria-label={label}>
      <div
        className={styles.progressBarFill}
        style={{ width: `${value}%` }}
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  );
}

export default ProgressBar;
