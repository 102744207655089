import React from 'react';
import styles from './FlashMessage.module.scss';
import { CloseDSIcon } from '../icons';

type FlashMessageProps = {
  message?: string;
  onCloseCallback?: () => void;
  style?: object;
};

function FlashMessage(props: FlashMessageProps) {
  const { message, onCloseCallback, style = {} } = props;
  return (
    <div className={styles.container} style={style}>
      <div className={styles.messageContainer}>
        <span className={styles.message}>{message}</span>
      </div>
      <div className={styles.closeButtonContainer}>
        <div role="button" tabIndex={0} className={styles.closeButton} onClick={onCloseCallback}>
          <CloseDSIcon color="#a5a5a5" width={17} height={19} />
        </div>
      </div>
    </div>
  );
}

export default FlashMessage;
