export enum FlashMessageStatus {
  success = 'success',
  error = 'error',
  warning = 'warning'
}

export type FlashMessageOptions = {
  status?: FlashMessageStatus;
  message: string;
  duration?: number | undefined;
  delay?: number | undefined;
  withProgressBar?: boolean | undefined;
  closeOnClick?: boolean | undefined;
};

export type FlashMessageData = {
  id: string;
} & Required<FlashMessageOptions>;
