import React, { useEffect, SyntheticEvent } from 'react';
import { Stack } from '@mui/material';
import RangeSlider from '../slider';
import InputMui from '../inputMui';
import { FilterRangeSliderProps } from './FilterRangeSliderProps';
import styles from './FilterRangeSlider.module.scss';

function FilterRangeSlider(props: FilterRangeSliderProps) {
  const {
    prefix,
    suffix,
    maxSign,
    showOnMax,
    label,
    dataKey,
    curMin,
    curMax,
    min,
    max,
    value,
    name,
    onChange,
    ...rest
  } = props;

  const currentUrlParams = new URLSearchParams(window.location.search);

  const [curMinState, setCurMinState] = React.useState<number>(curMin);
  const [curMaxState, setCurMaxState] = React.useState<number>(curMax);

  const setCurMin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value, 10);
    if (number > min && number < max) {
      setCurMinState(number);
      onChange({ curMin: number, curMax: curMaxState, min, max });
    } else {
      setCurMinState(curMinState);
    }
  };

  const setCurMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value, 10);
    if (number <= max && number > min) {
      setCurMaxState(number);
      onChange({ curMin: curMinState, curMax: number, min, max });
    } else {
      setCurMaxState(curMaxState);
    }
  };

  const handleMinMaxChangeWithSlider = (
    event: Event,
    sliderValue: number | number[],
    activeThumb: number
  ) => {
    if (typeof sliderValue === 'number') {
      return;
    }
    setCurMinState(sliderValue[0]);
    setCurMaxState(sliderValue[1]);
  };

  const handleMinMaxChangeCommitted = (
    _: Event | SyntheticEvent<Element, Event>,
    sliderValue: number | number[]
  ) => {
    if (typeof sliderValue === 'number') {
      return;
    }
    onChange({ curMin: sliderValue[0], curMax: sliderValue[1], min, max });
  };

  useEffect(() => {
    if (curMin === min) {
      setCurMinState(min);
    }

    if (curMax === max) {
      setCurMaxState(max);
    }
  }, [curMin, curMax]);

  const capitalizedName = name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
  const inputMaxName = `curMax${capitalizedName}`;
  const inputMinName = `curMin${capitalizedName}`;

  return (
    <div className={styles.containerFrame}>
      <RangeSlider
        curMin={curMinState}
        curMax={curMaxState}
        min={min}
        max={max}
        value={[curMinState, curMaxState]}
        {...rest}
        onChange={handleMinMaxChangeWithSlider}
        onChangeCommitted={handleMinMaxChangeCommitted}
        title={label}
        testID={name}
      />
      <Stack direction="row" spacing={2} alignItems="center">
        <InputMui
          name={inputMinName}
          value={curMinState}
          onChange={setCurMin}
          label="Min"
          prefix={prefix}
          data-testid={inputMinName}
        />
        <span>-</span>
        <InputMui
          name={inputMaxName}
          data-testid={inputMaxName}
          value={curMaxState}
          onChange={setCurMax}
          label="Max"
          showOnMax={showOnMax}
          maxSign={maxSign}
          max={max}
          prefix={prefix}
        />
      </Stack>
    </div>
  );
}

export default FilterRangeSlider;
