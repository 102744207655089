import React from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import EmberIframe from '../ember-iframe/EmberIframe';
import styles from './EmberCampaign.module.scss';
import campaignFetchers from '../../api/fetchers/campaign';
import userUpdaters from '../../api/updaters/user';
import useParams from '../../hooks/use-params/useParams';
import getStores from '../../utils/getStores';
import translate from '../../utils/translate';
import { PopularPaysLogoGreen } from '../icons';
import useToken from '../../hooks/use-token';
import LinkButton from '../link-button';

function EmberCampaign() {
  const { isAuthenticated } = useToken();
  const { rootStore } = getStores('rootStore');

  const { campaignId } = useParams();

  const campaignQuery = useQuery({
    queryKey: ['campaigns', campaignId],
    queryFn: campaignFetchers.campaign,
    meta: {
      id: campaignId
    }
  });

  const addCampaignCreatorMatch = useMutation({
    mutationKey: ['user', 'addCampaignCreatorMatch', campaignId],
    mutationFn: userUpdaters.addCampaignCreatorMatch
  });

  const { data, isLoading: isCampaignLoading } = campaignQuery;
  const campaignData = data?.data;

  const onSignUpAndApplyClick = (e: any) => {
    if (isAuthenticated) {
      addCampaignCreatorMatch.mutate(campaignId);
    } else {
      rootStore.setRedirectUrlAfterAuth(`/campaigns/${campaignId}/apply`);
    }
  };

  if (isCampaignLoading) {
    return null;
  }

  const url = isAuthenticated
    ? `/campaigns/${campaignId}/apply`
    : `/auth/login?campaign_direct_link=${campaignId}`;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <PopularPaysLogoGreen />
        </div>
        {campaignData?.isActive ? (
          <div className={styles.innerContainer}>
            <span className={styles.text}>{translate('campaign-invite.title')}</span>
            <LinkButton
              url={url}
              testID="campaign-invite__sign-up-and-apply-button"
              className={styles.buttonApply}
              to={{ url }}
              onClick={onSignUpAndApplyClick}>
              <span>{translate('campaign-invite.button-title')}</span>
            </LinkButton>
          </div>
        ) : (
          <span className={styles.closeText}>
            {translate('campaign-invite.button-title-closed')}
          </span>
        )}
      </div>
      <div className={styles.iframeContainer}>
        <EmberIframe isFullHeight />
      </div>
    </>
  );
}
export default EmberCampaign;
