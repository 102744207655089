import {
  Dialog as DialogMui,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled
} from '@mui/material';
import React from 'react';

import styles from './ModalLogin.module.scss';
import { ModalLoginProps } from './ModalLoginProps';

const StyledDialogContent = styled(DialogContent)`
  ${(props) => props.theme.breakpoints.down('mobile')} {
    background-color: #fcfcfd;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  margin-top: 70px;

  ${(props) => props.theme.breakpoints.down('mobile')} {
    margin-top: 40px;
  }
`;

const StyledDialog = styled(DialogMui)`
  .MuiPaper-root {
    width: 700px;
    overflow: hidden;
    border-radius: 35px;

    ${(props) => props.theme.breakpoints.down('mobile')} {
      margin: 0;
      align-self: end;
      max-height: calc(100% - 53px);
      border-radius: 10px;
    }
  }
`;

function ModalLogin(props: ModalLoginProps) {
  const { dividers, title, actions, onClose, open, children, testID, ...rest } = props;
  const testIDProps = {
    'data-testid': testID || undefined
  };
  return (
    <StyledDialog {...testIDProps} {...rest} open={open} onClose={onClose}>
      {title && <StyledDialogTitle className={styles.DialogTitle}>{title}</StyledDialogTitle>}
      <StyledDialogContent dividers={dividers}>{children}</StyledDialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </StyledDialog>
  );
}

export default ModalLogin;
