import { getEnv } from '../../config/environment';

export default function authenticateInstagram(
  userId: string | null,
  selectedPlatforms: string[],
  brandName: string
) {
  return new Promise((resolve, reject) => {
    const authUrl = getInstagramAuthUrl(userId, selectedPlatforms, brandName);
    window.location.href = authUrl;
  });
}

const getInstagramAuthUrl = (
  userId: string | null,
  selectedPlatforms: string[],
  brandName: string
): string => {
  const facebookAppId = getEnv().FACEBOOK_APP_ID;
  const redirectUrlBase = `${getEnv().DOMAIN_HOST}${window.location.pathname}`;
  const serverCallbackUri = getEnv().META_SERVER_CALLBACK_URI;
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set('selectedPlatforms', selectedPlatforms.join(','));
  if (brandName) {
    urlParams.set('brand', brandName);
  }

  const successRedirectUrl =
    `${redirectUrlBase}/callback?success=true&provider=facebook&redirectPath=${
      window.location.pathname
    }?${urlParams.toString()}`.replace('//callback', '/callback');

  const queryChar = redirectUrlBase.includes('?') ? '&' : '?';
  const failureRedirectUrl = `${redirectUrlBase}${queryChar}provider=instagram`;

  const state = {
    network: 'instagram',
    success_redirect_url: successRedirectUrl,
    failure_redirect_url: failureRedirectUrl,
    user_id: userId,
    redirect_uri: serverCallbackUri
  };

  const scopes = [
    'business_management',
    'instagram_basic',
    'instagram_manage_insights',
    'pages_manage_metadata',
    'pages_read_engagement',
    'pages_show_list'
  ];

  return [
    'https://www.facebook.com/v16.0/dialog/oauth',
    `?client_id=${facebookAppId}`,
    `&redirect_uri=${serverCallbackUri}`,
    `&response_type=code,granted_scopes`,
    `&scope=${scopes.join(',')}`,
    `&state=${encodeURIComponent(JSON.stringify(state))}`
  ].join('');
};
