import React from 'react';
import clsx from 'clsx';
import { Icon, Headline, Button } from '@lightricks/react-design-system';
import { Platform } from './types/platforms';
import translate from '../../../utils/translate';
import styles from './PlatformList.module.scss';
import sendSignupButtonPressedEvent from './utils/sendSignupButtonPressedEvent';

export type PlatformListProps = {
  selectedPlatforms: Platform[];
  connectedPlatforms: Platform[];
  loadingPlatform: string | null;
  onConnectPlatform: (platform: string) => void;
  screenId: string;
  screenName: string;
};

function PlatformList(props: PlatformListProps) {
  const {
    selectedPlatforms,
    connectedPlatforms,
    loadingPlatform,
    onConnectPlatform,
    screenId,
    screenName
  } = props;

  const renderActionButton = (platform: string, isConnected: boolean) => {
    if (platform === 'Facebook' || platform === 'YouTube') {
      return null;
    }
    if (isConnected) {
      return <Icon name="Actions-Patch-Accept-Line" size="large" appearance="brand" />;
    }

    return (
      <Button
        appearance="neutral"
        size="small"
        mode="plain"
        onClick={() => {
          sendSignupButtonPressedEvent(screenId, screenName, `${screenName}-connect`);
          onConnectPlatform(platform);
        }}
        className={styles.connectButton}
        icon={<Icon name="Actions-Add-Small1" size="large" appearance="white" />}
      />
    );
  };

  const sortedPlatforms = [...selectedPlatforms].sort((a, b) => {
    return a === 'Facebook' || a === 'YouTube' ? 1 : -1;
  });

  return (
    <div className={styles.platformList}>
      <div className={styles.platformRow}>
        <div className={clsx(styles.platformItem, styles.completed)}>
          <Icon name="Navigation-Mail" size="medium" appearance="primary" />
          <Headline size="xs">
            {translate('components.brand-safety.sign-up.platform-list.email')}
          </Headline>
        </div>
        <Icon name="Actions-Patch-Accept-Line" size="large" appearance="brand" />
      </div>

      {sortedPlatforms.map((platform) => {
        const isConnected = connectedPlatforms.includes(platform);
        const label =
          platform === 'Facebook' || platform === 'YouTube'
            ? translate('components.brand-safety.sign-up.platform-list.coming-soon')
            : platform;

        return (
          <div className={styles.platformRow} key={platform}>
            <div className={styles.platformItem}>
              <Icon name={`Social-${platform}` as any} size="medium" appearance="primary" />
              <Headline size="xs">{label}</Headline>
            </div>
            {renderActionButton(platform, isConnected)}
          </div>
        );
      })}
    </div>
  );
}

export default PlatformList;
