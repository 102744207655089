function getUrlParams(search = undefined): any {
  // get the current filters
  const currentParams = new URLSearchParams(search ?? window.location.search);
  const params = {};
  // iterate on the parameters and save existing parameters into params object
  currentParams.forEach((value, param) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    params[param] = Number.isNaN(Number(value)) ? value : parseInt(value, 10);
  });

  return params;
}

export default getUrlParams;
