import * as SentryDriver from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';
import { getEnv } from './environment';
import Sentry from '../services/sentry/Sentry';

const DEBUG_MODE = false;
Sentry.setDriver(SentryDriver, DEBUG_MODE);
Sentry.init({
  dsn: getEnv().VITE_SENTRY_DSN,
  integrations: [new SentryBrowser.BrowserTracing()],
  tracesSampleRate: 0.2,
  initialScope: {
    tags: { platform: 'React' }
  }
});
