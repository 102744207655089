import './src/config/init';
import './src/config/sentryInit';
import './src/config/analyticsInit';
import './src/config/cypressInit';
import './src/config/fortressInit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// uncomment this line to activate mirageJS server in development
// import './mirageInit';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
