import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import Stack from '../stack';
import { SingleQueryParamsObject } from '../filters-popup-canvas/FilterPopupParametersType';
import ButtonMui from '../button-mui';
import style from './FiltersPopupActionButtons.module.scss';
import campaigns from '../../../api/fetchers/campaigns';
import translate from '../../../utils/translate';
import { WhiteCircularLoader } from '../../icons';

function ButtonLoader() {
  return (
    <div className={style.loaderIcon}>
      <WhiteCircularLoader />
    </div>
  );
}

function FiltersPopupActionButtons({
  paramList,
  onShowGigsClick,
  onResetClick
}: {
  paramList: SingleQueryParamsObject[];
  onShowGigsClick: () => void;
  onResetClick: () => void;
}) {
  const paramsListObject = Object.assign({}, ...paramList);
  const counterQuery = useQuery({
    queryKey: ['count', paramsListObject],
    queryFn: () => campaigns.counter(paramsListObject),
    select: (data: any) => data
  });

  const { data: counterData, isLoading: isLoadingCounter } = counterQuery;
  const ShowGigsButton = styled(ButtonMui)({
    minWidth: '163px',
    height: '43px',
    alignItems: 'center'
  });

  return (
    <Stack
      direction="row"
      style={{
        width: '100%',
        justifyContent: 'space-between',
        padding: '0 14px',
        height: '97px',
        alignItems: 'center'
      }}>
      <ButtonMui key="cancel" onClick={onResetClick} type="linklike">
        {translate('components.filters-popup.action-buttons.reset')}
      </ButtonMui>
      <ShowGigsButton key="showgigs" onClick={onShowGigsClick} type="filled">
        {isLoadingCounter && <ButtonLoader />}
        {!isLoadingCounter &&
          translate('components.filters-popup.action-buttons.show', { total: counterData?.count })}
      </ShowGigsButton>
    </Stack>
  );
}

export default observer(FiltersPopupActionButtons);
