import { omit } from 'lodash';
import { jsonApiDelete, jsonApiFetch, jsonApiMutate } from '../utils/jsonapiClient';

/**
 * API convention is based on Axios client
 * https://axios-http.com/docs/api_intro
 */
const api = {
  get: <T>(path: string, config: any = {}) =>
    jsonApiFetch([path, config.params], { ...omit(config, ['params']) }),
  put: <T>(path: string, data: any, config: any = {}) =>
    jsonApiMutate([path, config.params], data, { method: 'PUT', ...omit(config, ['params']) }),
  post: <T>(path: string, data: any, config: any = {}) =>
    jsonApiMutate([path, config.params], data, { method: 'POST', ...omit(config, ['params']) }),
  delete: <T>(path: string, config: any = {}) =>
    jsonApiDelete([path, config.params], { ...omit(config, ['params']) })
};

export default api;
