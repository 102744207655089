import translate from '../../utils/translate';
import Sentry from '../sentry/Sentry';

export type VerifyOTPAndSaveTokenCookiesResponse = {
  token: string;
  refreshToken: string;
  refreshTokenExpirationS: number;
  isNew: boolean;
};

export interface FortressDriver {
  generateOTP(email: string): void;

  verifyOTPAndSaveTokenCookies(
    email: string,
    otpCode: string
  ): VerifyOTPAndSaveTokenCookiesResponse;

  getAccessToken(): Promise<string | null>;

  clearCookieTokens(): void;
}

type OtpStatusErrorMap = {
  [key: string]: string;
};

export const GENERATE_OTP_STATUS_ERROR_MAP: OtpStatusErrorMap = {
  default: 'services.fortress.errors.generate-otp.default',
  400: 'services.fortress.errors.generate-otp.400',
  429: 'services.fortress.errors.generate-otp.429',
  409: 'services.fortress.errors.generate-otp.409'
};

export const LOGIN_OTP_STATUS_ERROR_MAP: OtpStatusErrorMap = {
  default: 'services.fortress.errors.verify-otp.default',
  400: 'services.fortress.errors.verify-otp.400',
  401: 'services.fortress.errors.verify-otp.401',
  409: 'services.fortress.errors.verify-otp.409'
};

interface Subscriber {
  key: string;
  callback: () => void;
}

class Fortress {
  private driver: FortressDriver | undefined;

  private subscribers: Subscriber[] = [];

  SAVE_FORTRESS_COOKIES = 'save_fortress_cookies';

  setDriver(driver: FortressDriver) {
    this.driver = driver;
  }

  async generateOTP(email: string): Promise<void> {
    try {
      await this.driver!.generateOTP(email);
    } catch (error: any) {
      const { response } = error;
      Sentry.captureException(error, { title: 'Fortress Generate OTP Error', email, error });
      const status: string = response?.status || 'default';
      const errorLocaleLabelKey = GENERATE_OTP_STATUS_ERROR_MAP[status];
      throw new Error(translate(errorLocaleLabelKey));
    }
  }

  async verifyOTPAndSaveTokenCookies(
    email: string,
    otpCode: string
  ): Promise<VerifyOTPAndSaveTokenCookiesResponse> {
    try {
      const response = await this.driver!.verifyOTPAndSaveTokenCookies(email, otpCode);
      this.notifySubscribers(this.SAVE_FORTRESS_COOKIES);
      return response;
    } catch (error: any) {
      const { response } = error;
      Sentry.captureException(error, {
        title: 'Fortress verifyOTPAndSaveTokenCookies Error',
        email,
        error
      });
      const errorLocaleLabelKey =
        LOGIN_OTP_STATUS_ERROR_MAP[response?.status] || LOGIN_OTP_STATUS_ERROR_MAP.default;
      throw new Error(translate(errorLocaleLabelKey));
    }
  }

  async getAccessToken(): Promise<string | null> {
    try {
      const accessToken = await this.driver!.getAccessToken();
      if (!accessToken) {
        return null;
      }
      return accessToken;
    } catch (error: any) {
      const { response } = error;
      if (![401, 500, 429].includes(response?.status)) {
        Sentry.captureException(error, { title: 'Fortress getAccessToken Error', error });
      }
      return null;
    }
  }

  clearCookieTokens(): void {
    try {
      this.driver!.clearCookieTokens();
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  addSubscriber = (key: string, callback: () => void): any => {
    this.subscribers.push({ key, callback });
  };

  private notifySubscribers = (key: string): void => {
    this.subscribers.forEach((subscriber) => {
      if (subscriber.key === key) {
        subscriber.callback();
      }
    });
  };

  removeSubscriber = (key: string): number => {
    const beforeAmount = this.subscribers.length;
    this.subscribers = this.subscribers.filter((subscriber) => subscriber.key !== key);
    const afterAmount = this.subscribers.length;

    return beforeAmount - afterAmount;
  };

  removeAllSubscribers = (): void => {
    this.subscribers = [];
  };
}

export default new Fortress();
