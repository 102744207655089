const analyticsEvents = {
  LOGIN: {
    CLICK_SIGN_UP: 'click_sign_up',
    SIGN_UP: 'sign_up',
    CLICK_LOGIN: 'click_login',
    LOGIN: 'login',
    CLICK_FORGOT_PASSWORD: 'click_forgot_password',
    LOGIN_FORGOT_PASSWORD_RESET: 'login_forgot_password_reset',
  },
  GIGS: {
    CLICK_CHOOSE_GIG_CATEGORY: 'click_choose_gig_category',
    CLICK_CHOOSE_GIG: 'click_choose_gig',
    CHECK_APPLY_GIG_AVAILABILITY: 'check_apply_gig_availability',
    CLICK_APPLY_TO_GIG: 'click_apply_to_gig',
    CLICK_DISMISS_APPLY_TO_GIG_WINDOW: 'click_dismiss_apply_to_gig_window',
    CLICK_DISMISS_GIG_MISSMATCH: 'click_dismiss_gig_missmatch',
    CLICK_SUBMIT_APPLICATION: 'click_submit_application',
    CHECK_SUBMIT_APPLICATION: 'check_submit_application',
    CLICK_READ_TERMS: 'click_read_terms',
  },
  LINK_SOCIAL_PROFILE: {
    CLICK_LINK_SOCIAL_PROFILE: 'click_link_social_profile',
    CLICK_CONNECT_TO_SOCIAL_LIST: 'click_connect_to_social_list',
    CHECK_LINK_SOCIAL_PROFILE: 'check_link_social_profile',
  },
  COMPLETE_PROFILE: {
    CLICK_COMPLETE_PROFILE: 'click_complete_profile',
    CLICK_UPLOAD_FILE: 'click_upload_file',
    CHECK_UPLOAD_FILE: 'check_upload_file',
  },
  MAIN_NAVIGATION: {
    CLICK_MENU: 'click_menu',
  },
  SETTINGS: {
    CLICK_SETTINGS: 'click_settings',
  },
  SUPPORT: {
    CLICK_SUPPORT: 'click_support',
  },
  LEGAL_SOCIAL: {
    CLICK_LINK: 'why_linking_social_accounts_matters',
  },
  GENERAL: {
    CLICK: 'click',
    UPLOAD: 'upload',
  },
};

export default analyticsEvents;
