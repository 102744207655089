/* eslint-disable */
// @ts-nocheck
import { rootStore } from '../stores';

type Stores = {
  [key: string]: any;
};

/**
 * custom getStores function => return given stores
 * usage:
 *
 * const {rootStore, userStore} = getStores('rootStore', 'userStore'');
 * @param args
 * @return {*}
 */
const getStores = (...args: string[]): Stores => {
  return args.reduce((acc, curr) => {
    if (curr === 'rootStore') {
      acc[curr] = rootStore();
      return acc;
    }
    const store = rootStore()[curr];
    if (store) {
      acc[curr] = store;
    } else {
      throw new Error(`getStores - could not find store: ${curr}`);
    }
    return acc;
  }, {} as Stores);
};

export default getStores;
