import { useEffect } from 'react';

const TITLE_POSTFIX = 'Popular Pays';
const TITLE_SEPARATOR = ' | ';
export default function usePageTitle(pageTitle?: string) {
  useEffect(() => {
    const titleStack = [];
    if (pageTitle) {
      titleStack.push(pageTitle);
      titleStack.push(TITLE_SEPARATOR);
    }
    titleStack.push(TITLE_POSTFIX);
    document.title = `${titleStack.join('')}`;
  }, [pageTitle]);

  return null;
}
