import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import styles from './Link.module.scss';
import { LinkProps } from './LinkProps';

function Link(props: LinkProps) {
  const { to, children, testID, ...rest } = props;

  const testIDProps = {
    'data-testid': testID || undefined
  };

  return (
    <ReactRouterLink className={styles.linkButton} to={to} {...testIDProps} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

export default Link;
