import {
  Dialog as DialogMui,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled
} from '@mui/material';
import React from 'react';

import { ModalProps } from './ModalProps';
import styles from './style';

const StyledDialog = styled(DialogMui)({
  '.MuiPaper-root': {
    width: '700px',
    overflow: 'hidden',
    '@media (max-width: 780px)': {
      margin: '0px',
      alignSelf: 'end',
      maxHeight: 'calc(100% - 53px)',
      borderRadius: '10px'
    }
  }
});

const StyledDialogContent = styled(DialogContent)({
  width: '672px',
  padding: '0px 28px',
  '@media (max-width: 780px)': {
    width: '100%',
    backgroundColor: '#FCFCFD'
  }
});

const StyledDialogContentText = styled(DialogContentText)({
  width: '647px',
  '@media (max-width: 780px)': {
    width: '100%'
  }
});

function Modal(props: ModalProps) {
  const { dividers, title, actions, onClose, open, children, ...rest } = props;

  return (
    <StyledDialog {...rest} open={open} onClose={onClose}>
      {title && <DialogTitle style={styles.DialogTitle}>{title}</DialogTitle>}
      <StyledDialogContent dividers={dividers}>
        <StyledDialogContentText>{children}</StyledDialogContentText>
      </StyledDialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </StyledDialog>
  );
}

export default Modal;
