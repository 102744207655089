import React, { useEffect, useState } from 'react';
import { Button, Headline, Body, Input } from '@lightricks/react-design-system';
import { v4 as uuid } from 'uuid';
import { useSearchParams } from 'react-router-dom';
import isMobile from '../../../utils/identifyDevice';
import styles from './Login.module.scss';
import useAuthenticate from '../../../hooks/use-authenticate';
import OtpVerificationModal from '../../../components/otp-verification-modal';
import useParams from '../../../hooks/use-params/useParams';
import translate from '../../../utils/translate';
import emailValidator from '../../../utils/validators/email';
import usePageTitle from '../../../hooks/use-page-title';
import Link from '../../../components/link';
import DeltaHelper from '../../../services/delta/DeltaHelper';
import {
  buttonNames,
  deltaClientEvents,
  flowNames,
  reasonNames,
  resultNames,
  screenNames
} from '../../../lib/delta/deltaConstants';
import Delta from '../../../utils/wrappers/Delta';

function Login() {
  usePageTitle(translate('meta-tags.login.title'));
  const params = useParams();
  const { onAuthenticate: onAuthenticateLogin, onSubmit } = useAuthenticate();
  const isMobileDevice = isMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    email: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  const [formValidation, setFormValidation] = useState({
    email: false
  });

  const [searchParams] = useSearchParams();
  const redirectToSocialNetworks = searchParams.get('redirect') === 'social-networks';

  useEffect(() => {
    const loginFlowEvent = DeltaHelper.createFlow(flowNames.auth.login);
    DeltaHelper.startFlow(flowNames.auth.login);
    DeltaHelper.screenPresented(screenNames.auth.login, '', {
      trigger_flow_id: loginFlowEvent.flow_id,
      trigger_flow_name: loginFlowEvent.flow_name
    });
  }, []);

  const setFormValue = (key: string) => (value: string) => {
    setForm({ ...form, [key]: value });
    setErrorMessage('');
  };

  const onError = (error: string, stepDateEvent: Record<string, unknown>) => {
    setErrorMessage(error);

    sendDeltaEvents(deltaClientEvents.auth.login_step_ended, {
      ...stepDateEvent,
      reason: reasonNames.failure,
      error
    });
  };

  const handleSubmit = async () => {
    const loginFlowEvent = DeltaHelper.getFlow(flowNames.auth.login);
    sendDeltaEvents(deltaClientEvents.generic.button_pressed, {
      button_name: buttonNames.auth.login,
      screen_name: screenNames.auth.login,
      flow_id: loginFlowEvent.flow_id,
      flow_name: loginFlowEvent.flow_name,
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id: DeltaHelper.getActiveScreenPresented().screen_presentation_id
    });

    const processId = uuid();
    const stepDateEvent = {
      flow_id: loginFlowEvent.flow_id,
      process_id: processId,
      login_provider: 'fortress',
      request_type: 'send_otp',
      input_value: form.email
    };
    sendDeltaEvents(deltaClientEvents.auth.login_step_started, stepDateEvent);

    if (!formValidation.email || isSubmitting) {
      if (form.email === '') {
        setErrorMessage(translate('components.login-form.empty-email-error'));
      }

      sendDeltaEvents(deltaClientEvents.auth.login_step_ended, {
        ...stepDateEvent,
        reason: reasonNames.failure,
        error: errorMessage || translate('components.login-form.invalid-email-error')
      });
      return;
    }
    setIsSubmitting(true);
    const isSubmitSuccess = await onSubmit('login', form.email, (error: string) =>
      onError(error, stepDateEvent)
    );
    setIsSubmitting(false);
    if (isSubmitSuccess) {
      setIsModalOpen(true);
      setErrorMessage('');

      sendDeltaEvents(deltaClientEvents.auth.login_step_ended, {
        ...stepDateEvent,
        reason: reasonNames.success
      });
      DeltaHelper.screenDismissed(screenNames.auth.login, screenNames.auth.login_otp);
      DeltaHelper.screenPresented(screenNames.auth.login_otp, screenNames.auth.login);
    }
  };

  const onAuthenticate = async (token: string, stepDateEvent: Record<string, unknown>) => {
    const loginFormData = {
      ...params,
      ...form,
      password: token
    };
    await onAuthenticateLogin(loginFormData, (error: string) => onError(error, stepDateEvent));
    setIsModalOpen(false);
    sendDeltaEvents(deltaClientEvents.auth.login_step_ended, {
      ...stepDateEvent,
      creator_id: Delta.creatorId || '',
      reason: reasonNames.success
    });
    DeltaHelper.screenDismissed(screenNames.auth.login, '');
    DeltaHelper.endFlow(flowNames.auth.login, {
      result: resultNames.success
    });
  };

  const setEmailValidation = (isValid: boolean) => {
    setFormValidation({ ...formValidation, email: isValid });
  };

  const sendDeltaEvents = (eventName: string, eventData: object) => {
    Delta.sendEvent(eventName, eventData);
  };

  const onNavigateToSignup = (e: Event) => {
    e.stopPropagation();
    DeltaHelper.screenDismissed(screenNames.auth.login, screenNames.auth.signup);
    DeltaHelper.endFlow(flowNames.auth.login, {
      result: resultNames.cancelled
    });
  };

  const emailValidators = [
    {
      validator: (email: string | undefined) => email !== '',
      errorMessage: translate('components.login-form.empty-email-error')
    },
    {
      validator: emailValidator,
      errorMessage: translate('components.login-form.invalid-email-error')
    }
  ];

  return (
    <div data-testid="login" className={styles.loginFormWrapper}>
      <OtpVerificationModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        email={form.email}
        authenticateCallback={onAuthenticate}
        authFlowType={flowNames.auth.login}
        redirectToSocialNetworks={redirectToSocialNetworks}
      />
      <div className={styles.text}>
        <Headline size={isMobileDevice ? 'md' : 'xl'} className={styles.loginTitle}>
          {translate('templates.auth.login.title')}
        </Headline>
        <Body size={isMobileDevice ? 'md' : 'xl'}>
          {translate('templates.auth.login.welcome-message')}
        </Body>
      </div>
      <form
        onSubmit={(event: React.FormEvent): void => {
          event.preventDefault();
          handleSubmit();
        }}
        className={styles.formContent}>
        <div className={styles.formInput}>
          <Input
            testID="login--email-input"
            validatorOptions={{
              type: 'email',
              onValidation: setEmailValidation,
              validators: emailValidators,
              overrideError: errorMessage,
              onChangeText: setFormValue('email')
            }}
            label={translate('components.login-form.email-label')}
            placeholder={translate('components.login-form.email-placeholder')}
            value={form.email}
          />
        </div>
        <Button
          testID="login--submit-button"
          type="submit"
          disabled={isSubmitting}
          appearance="brand"
          mode="filled"
          size="large"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          fullWidth>
          {translate('templates.auth.login.submit-label')}
        </Button>
      </form>
      <div className={styles.formNoteTerm}>
        <Body size={isMobileDevice ? 'sm' : 'lg'}>
          {translate('templates.auth.login.note--terms.text')}
          <a
            className={styles.formLink}
            href={translate('templates.auth.login.note--terms.link-term')}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}>
            {translate('templates.auth.login.note--terms.text-link')}
          </a>
        </Body>
      </div>
      <div className={styles.formNoteSignup}>
        <Body size={isMobileDevice ? 'md' : 'lg'} className={styles.text}>
          {translate('templates.auth.login.note--sign-up.text')}
          <Link
            className={styles.formLink}
            to={translate('templates.auth.login.note--sign-up.link-term')}
            onClick={onNavigateToSignup}>
            {translate('templates.auth.login.note--sign-up.text-link')}
          </Link>
        </Body>
      </div>
    </div>
  );
}

export default Login;
