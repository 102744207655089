import React from 'react';
import styles from './MenuItem.module.scss';
import isMobile from '../../../utils/identifyDevice';
import { Icon } from '../../icons';

interface MenuItemProps {
  id?: string;
  isActive: boolean;
  title: string;
  icon?: Icon;
  hasUnreadNotification?: boolean;
  messagesIndicatorSize?: number;
}

function MenuItem(props: MenuItemProps) {
  const {
    id,
    icon: IconElement,
    title,
    isActive,
    hasUnreadNotification,
    messagesIndicatorSize
  } = props;

  const xStackActiveStyle = isActive ? styles.activeLink : '';
  const textActiveStyle = isActive ? styles.activeText : '';

  return (
    <div className={`${styles.container} ${xStackActiveStyle}`}>
      <div className={styles.link}>
        {!!IconElement && (
          <IconElement width={22} color={isActive && isMobile() ? '#07C98F' : '#404040'} />
        )}
        <div className={styles.textContainer} data-cy={title} data-testid={title}>
          <span className={`${styles.text} ${textActiveStyle}`}>{title}</span>
        </div>
      </div>
      {hasUnreadNotification ? (
        <div
          className={styles.blueDot}
          data-testid={`main-menu__${id}__unread-collaborations-indicator`}>
          <div
            style={{
              height: messagesIndicatorSize || 10,
              width: messagesIndicatorSize || 10
            }}
            className={styles.circle}
          />
        </div>
      ) : null}
    </div>
  );
}

export default MenuItem;
