import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Icon, Textarea } from '@lightricks/react-design-system';
import translate from '../../utils/translate';
import isMobile from '../../utils/identifyDevice';
import styles from './ReportInappropriateContent.module.scss';
import jsonData from './reportTypeList.json';

type ReportInappropriateContentProps = {
  testID: string;
  isOpen: boolean;
  onClose: () => void;
};

interface Item {
  title: string;
  items?: Item[];
}

interface Data {
  title: string;
  items: Item[];
}

enum ModalState {
  URL,
  OPTION_REPORT,
  SOMETHING_REPORT,
  DONE
}

function ReportInappropriateContent(props: ReportInappropriateContentProps) {
  const { testID, isOpen, onClose } = props;

  const [url, setURL] = useState('');
  const [somethingElseReport, setSomethingElseReport] = useState('');
  const [allOptionReport, setAllOptionReport] = useState<Data[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [listSelectedItemReport, setListSelectedItemReport] = useState<Item[]>([]);

  const [subTitle, setSubTitle] = useState('');

  const [stateModal, setStateModal] = useState<ModalState>(ModalState.URL);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    setAllOptionReport(jsonData);
  }, []);

  const openOptionReportModal = (item: any) => {
    if (item.title) {
      if (listSelectedItemReport.length === 0)
        setSubTitle(translate('components.report-inappropriate-content.subtitle-report-content'));
      else setSubTitle(item.title);
      const updatedListSelectedItem = [...listSelectedItemReport, item];
      setListSelectedItemReport(updatedListSelectedItem);
    } else setSubTitle('');
    setSelectedItems(item.items);
    setStateModal(ModalState.OPTION_REPORT);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    onClose();
    setURL('');
    setListSelectedItemReport([]);
    setSomethingElseReport('');
    setStateModal(ModalState.URL);
  };

  const goBackInDoneModal = () => {
    const updatedListSelectedItem = listSelectedItemReport.slice(
      0,
      listSelectedItemReport.length - 1
    );
    setListSelectedItemReport(updatedListSelectedItem);
    setStateModal(ModalState.OPTION_REPORT);
  };

  const goBackInOptionReportModal = () => {
    if (listSelectedItemReport.length === 0) {
      setStateModal(ModalState.URL);
    } else if (listSelectedItemReport.length === 1) {
      const updatedListSelectedItem = listSelectedItemReport.slice(
        0,
        listSelectedItemReport.length - 1
      );
      setListSelectedItemReport(updatedListSelectedItem);
      openOptionReportModal(listSelectedItemReport[listSelectedItemReport.length - 1].items);
    } else {
      const updatedListSelectedItem = listSelectedItemReport.slice(
        0,
        listSelectedItemReport.length - 1
      );
      setListSelectedItemReport(updatedListSelectedItem);
      const lastItem = updatedListSelectedItem[updatedListSelectedItem.length - 1];
      if (lastItem && lastItem.items) {
        setSelectedItems(lastItem.items);
      }
      if (updatedListSelectedItem.length === 1)
        setSubTitle(translate('components.report-inappropriate-content.subtitle-report-content'));
      else setSubTitle(updatedListSelectedItem[updatedListSelectedItem.length - 1].title);
    }
  };

  const goBackInSomethingElseReportModal = () => {
    setStateModal(ModalState.OPTION_REPORT);
    openOptionReportModal(listSelectedItemReport[listSelectedItemReport.length].items);
  };

  const [isButtonActive, setIsButtonActive] = useState(false);
  useEffect(() => {
    setIsButtonActive(url.length > 0);
  }, [url]);
  const getUrlModal = () => {
    return (
      <>
        <section className={styles.sectionHeader}>
          <h2 className={styles.title}>
            {isMobile()
              ? translate('components.report-inappropriate-content.title-report-mobile')
              : translate('components.report-inappropriate-content.title-report-content')}
          </h2>
        </section>
        <span className={styles.subtitleInput}>
          {translate('components.report-inappropriate-content.title-report-url')}
        </span>
        <section className={styles.sectionForm}>
          <Input
            type="text"
            label="URL"
            value={url}
            validatorOptions={{
              onChangeText: (value: string) => setURL(value),
              onValidation: (isValid: boolean) => setIsButtonActive(isValid),
              validators: [
                {
                  validator: (value: string) => value !== ''
                }
              ]
            }}
          />
        </section>
        <section className={styles.sectionFooter}>
          <Button
            appearance="neutral"
            mode="filled"
            size="medium"
            disabled={!isButtonActive}
            onClick={openOptionReportModal}>
            Next
          </Button>
        </section>
      </>
    );
  };

  const getOptionReportModal = (items: any) => {
    return (
      <>
        <section className={styles.sectionHeader}>
          <div
            role="button"
            tabIndex={0}
            className={styles.backButton}
            onClick={goBackInOptionReportModal}>
            <Icon size="large" appearance="neutral" name="Actions-Arrow-Back-Small" />
          </div>
          <h1 className={styles.title}>
            {isMobile()
              ? translate('components.report-inappropriate-content.title-report-mobile')
              : translate('components.report-inappropriate-content.title-report-content')}
          </h1>
        </section>
        <section className={styles.sectionContent}>
          {subTitle ? <span className={styles.subtitleContent}>{subTitle}</span> : <span />}
        </section>
        <ul className={styles.listItem}>
          {(selectedItems || items).map((item) =>
            item.items ? (
              <li key={item.title}>
                {' '}
                <div
                  role="button"
                  tabIndex={0}
                  className={styles.itemWithChildren}
                  onClick={() => openOptionReportModal(item)}>
                  <span>{item.title}</span>
                  <Icon size="large" appearance="neutral" name="Actions-Arrow-Forward-Small" />
                </div>
              </li>
            ) : (
              <li key={item.title}>
                {' '}
                <span
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    item.title === 'Something else'
                      ? setStateModal(ModalState.SOMETHING_REPORT)
                      : doneAction(item.title)
                  }>
                  {item.title}{' '}
                </span>
              </li>
            )
          )}
        </ul>
      </>
    );
  };

  const getSomethingElseReportModal = () => {
    return (
      <>
        <section className={styles.sectionHeader}>
          <div
            role="button"
            tabIndex={0}
            className={styles.backButton}
            onClick={goBackInSomethingElseReportModal}>
            <Icon size="large" appearance="neutral" name="Actions-Arrow-Back-Small" />
          </div>
          <h1 className={styles.title}>
            {isMobile()
              ? translate('components.report-inappropriate-content.title-report-mobile')
              : translate('components.report-inappropriate-content.title-report-content')}
          </h1>
        </section>
        <span className={styles.subtitleInput}>Please share what made you uncomfortable</span>
        <section className={styles.sectionForm}>
          <Textarea
            label=""
            testID="something-else-report-textarea"
            placeholder="Let us know..."
            supportingText=""
            minRows={7.5}
            value={somethingElseReport}
            onChange={(event) => setSomethingElseReport(event.target.value)}
          />
        </section>
        <section className={styles.sectionFooter}>
          <Button
            appearance="neutral"
            mode="filled"
            size="medium"
            disabled={!somethingElseReport}
            onClick={() => doneAction(somethingElseReport)}>
            Done
          </Button>
        </section>
      </>
    );
  };
  const doneAction = (lastTitle: string) => {
    const recipientEmail = 'support@popularpays.Zendesk.com';
    const subject = translate(
      'components.report-inappropriate-content.report-content-email-subject'
    );

    const body = translate('components.report-inappropriate-content.report-content-email-message', {
      url,
      typeReport: `${listSelectedItemReport.map((item) => item.title)}, ${lastTitle}`
    });

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
    closeModal();
  };

  const getDoneModal = () => {
    return (
      <>
        <div role="button" tabIndex={0} onClick={goBackInDoneModal}>
          <Icon size="large" appearance="brand" name="Actions-Patch-Accept-Fill" />
        </div>
        <div className={styles.contentDoneModal}>
          <h1 className={styles.title}>
            {translate('components.report-inappropriate-content.report-done.title')}
          </h1>
          <span> {translate('components.report-inappropriate-content.report-done.subtitle')}</span>
        </div>
        <Button appearance="neutral" mode="filled" size="medium" onClick={closeModal}>
          Done
        </Button>
      </>
    );
  };
  const getModal = () => {
    return (
      <Modal open={isOpen || modalIsOpen} showCloseButton handleClose={closeModal}>
        <>
          {stateModal === ModalState.URL && getUrlModal()}
          {stateModal === ModalState.OPTION_REPORT && getOptionReportModal(allOptionReport)}
          {stateModal === ModalState.SOMETHING_REPORT && getSomethingElseReportModal()}
          {stateModal === ModalState.DONE && getDoneModal()}
        </>
      </Modal>
    );
  };

  return <div key={testID}>{getModal()}</div>;
}

export default ReportInappropriateContent;
