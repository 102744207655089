const isMobile = () => {
  const resolution = window.innerWidth;
  const isMobileSize = resolution >= 320 && resolution <= 768;

  const mobile = Boolean(
    navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
  );

  return mobile || isMobileSize;
};

export default isMobile;
