/**
 * helper function for preventing circular entries while serializing an object using JSON.stringify
 * usage => JSON.stringify(data, getCircularReplacer());
 */
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return undefined;
      }
      seen.add(value);
    }
    return value;
  };
};

export default getCircularReplacer;
