import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Headline, Body, Label } from '@lightricks/react-design-system';
import signupAnimation from '../../../../assets/lotties/brand-safety/signup_screen_animation.json';
import translate from '../../../../utils/translate';
import styles from './BrandIntro.module.scss';

export type BrandIntroProps = {
  brandName: string | undefined;
  onJoinClick: () => void;
  onLoginClick: () => void;
};

function BrandIntro(props: BrandIntroProps) {
  const { brandName, onJoinClick, onLoginClick } = props;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.animationContainer}>
          <Player autoplay loop src={signupAnimation} style={{ width: '100%', height: '100%' }} />
        </div>
        <Body size="lg" className={styles.body}>
          {translate('components.register-form.brand-safety.headline')}
        </Body>
        <Headline size="lg" className={styles.headline}>
          {translate('components.register-form.brand-safety.sub-headline')}
        </Headline>
        <Headline size="lg" className={styles.brandName}>
          {brandName}
        </Headline>
        <div className={styles.subContent}>
          <Button
            appearance="neutral"
            mode="filled"
            size="large"
            onClick={onJoinClick}
            className={styles.joinWithEmailButton}
            data-testid="join-now-button">
            {translate('components.register-form.brand-safety.join')}
          </Button>
          <Label size="lg" className={styles.signInLabel}>
            {translate('components.register-form.brand-safety.already-have-account')} &nbsp;
            <span
              onClick={onLoginClick}
              className={styles.signInLink}
              data-testid="login-button"
              role="button"
              tabIndex={0}>
              {translate('components.register-form.brand-safety.login')}
            </span>
          </Label>
        </div>
      </div>
    </div>
  );
}

export default BrandIntro;
