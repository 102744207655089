import { makeAutoObservable } from 'mobx';

import { filterPopupParametersStoreInitialStateZero } from '../components/filters-popup/filters-popup-canvas/FilterPopupParametersStoreZeroInitialState';
import {
  FilterPopupParameterOptions,
  SingleQueryParamsObject
} from '../components/filters-popup/filters-popup-canvas/FilterPopupParametersType';

import { filterPopupParametersStoreInitialState } from '../components/filters-popup/filters-popup-canvas/FilterPopupParametersStoreInitialState';
import queryParamsSpecs, { ParamSpecType } from '../components/filters-popup/FilterParamsSpecs';
import { RootStore } from './RootStore.d';

class FilterPopupParametersStore {
  root: RootStore;

  store = filterPopupParametersStoreInitialState;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }

  resetPopupParameters = () => {
    const completeQueryAction: string[] = [];
    const queryParamsList: SingleQueryParamsObject[] = [];
    this.store.filterPopupParameters.currentQueryParamsList = queryParamsList;
    this.store.filterPopupParameters.currentQueryParamsUrl = completeQueryAction.join('&');
    this.store.filterPopupParameters.isDirty = false;
    this.store = filterPopupParametersStoreInitialStateZero;
    // this.prepareFiltersQueryParams();
  };

  get filterPopupParameters() {
    return this.store.filterPopupParameters;
  }

  updateFilterPopupParametersByKeys = (key: keyof FilterPopupParameterOptions, value: any) => {
    this.store.filterPopupParameters[key] = value;
    this.prepareFiltersQueryParams();
  };

  prepareFiltersQueryParams() {
    const completeQueryAction: string[] = [];
    const queryParamsList: SingleQueryParamsObject[] = [];

    queryParamsSpecs.forEach((paramSpec: ParamSpecType) => {
      const { paramDataFrom, paramKey, paramType }: ParamSpecType = paramSpec;
      const paramData: any = this.store.filterPopupParameters[paramDataFrom];
      if (paramType === 'object') {
        const singleValueLine: string[] = [];
        Object.keys(paramData).forEach((key) => {
          if (paramData[key] && paramData[key] === true) {
            singleValueLine.push(`${key}`);
          }
        });
        if (singleValueLine.length > 0) {
          const valueline = singleValueLine.join(',');
          queryParamsList.push({
            [paramKey]: valueline
          });
          completeQueryAction.push(`${paramKey}=${valueline}`);
        }
      } else if (paramType === 'array') {
        if (paramData.length > 0) {
          const valueline = paramData.join(',');
          completeQueryAction.push(`${paramKey}=${valueline}`);
          queryParamsList.push({
            [paramKey]: valueline
          });
        }
      } else if (paramType === 'range') {
        const [minKey, maxKey] = paramKey.split(',');
        if (paramData.curMin > -1 && paramData.curMax > -1) {
          if (paramData.curMin > paramData.min) {
            completeQueryAction.push(`${minKey}=${paramData.curMin}`);
          }
          if (paramData.curMax < paramData.max) {
            completeQueryAction.push(`${maxKey}=${paramData.curMax}`);
            queryParamsList.push({
              [maxKey]: paramData.curMax
            });
          }
          queryParamsList.push({
            [minKey]: paramData.curMin
          });
        }
      }
    });

    this.store.filterPopupParameters.currentQueryParamsList = queryParamsList;
    this.store.filterPopupParameters.currentQueryParamsUrl = completeQueryAction.join('&');
  }
}

export default FilterPopupParametersStore;
