import api from '../index';

const userFetchers = {
  user: () =>
    api.get('/user', {
      params: {
        include: ['identities', 'profile']
      }
    }),
  collaborations: () =>
    api.get('/user/collaborations', {
      params: {
        per_page: 1
      }
    })
};

export default userFetchers;
