import React, { useEffect, useState, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BANNER_NOTIFICATION_DATA, BannerContentOptions } from './bannerSettings';
import profileFetchers from '../../api/fetchers/profile';
import { NETWORKS } from '../../utils/constants';
import { WhiteXIcon } from '../icons';
import { DEPRECATED_NETWORK_IDS } from '../../config/constants';
import styles from './Banner.module.scss';
import LinkButton from '../link-button';

type Identity = { needsRelink: boolean; provider: NETWORKS };

export type BannerProps = {
  emberModalIsOpen: boolean;
  userData: {
    identities: Identity[];
    needsToLinkPaymentProcessor: boolean;
  };
  isLoadingUser: boolean;
};

function Banner(props: BannerProps) {
  const { maintenanceMode } = useFlags();
  const { emberModalIsOpen, userData, isLoadingUser } = props;
  const [bannerCaseName, setBannerCaseName] = useState<BannerContentOptions | null>(null);
  const [relinkedProvider, setRelinkedProvider] = useState<NETWORKS | null>(null);

  // Filters out identities that belong to deprecated networks
  const userIdentities =
    userData?.identities.filter((i: Identity) => !DEPRECATED_NETWORK_IDS.includes(i.provider)) ||
    [];

  const needsProviderRelink = useMemo(
    () => userIdentities.some((identity: Identity) => identity.needsRelink),
    [userIdentities]
  );

  const NetworksOrder = Object.values(NETWORKS);

  const identitiesByImportance = userIdentities
    .filter((identity: Identity) => identity.needsRelink)
    .slice()
    .sort(
      (a: Identity, b: Identity) =>
        NetworksOrder.indexOf(a.provider) - NetworksOrder.indexOf(b.provider)
    );
  const identitiesRelinkedByImportance = identitiesByImportance.filter(
    (identity: Identity) => identity.needsRelink
  );

  useEffect(() => {
    if (maintenanceMode) {
      setBannerCaseName(BannerContentOptions.MAINTENANCE_MODE);
    } else if (userData?.needsToLinkPaymentProcessor) {
      setBannerCaseName(BannerContentOptions.NEEDS_TO_LINK_PAYMENT_PROCESSOR);
    } else if (needsProviderRelink) {
      setRelinkedProvider(identitiesRelinkedByImportance[0].provider);
      setBannerCaseName(BannerContentOptions.NEEDS_PROVIDER_RELINK);
    }
  }, [
    maintenanceMode,
    userIdentities,
    needsProviderRelink,
    identitiesRelinkedByImportance,
    userData
  ]);

  const onDismiss = () => {
    setBannerCaseName(null);
  };

  const isShowBanner = bannerCaseName !== null;

  if (!isShowBanner || isLoadingUser) {
    return null;
  }

  const bannerData = BANNER_NOTIFICATION_DATA[bannerCaseName];
  const { linkUrl, linkText, icon, text, canDismiss } = bannerData;
  const dataTestBannerNotification =
    bannerCaseName === BannerContentOptions.NEEDS_PROVIDER_RELINK
      ? relinkedProvider
      : 'link-payment-processor';

  return (
    <div style={{ filter: emberModalIsOpen ? 'brightness(0.8)' : undefined }}>
      <div className={styles.container} data-testid={dataTestBannerNotification}>
        <div className={styles.message}>
          <span className={styles.text}>
            {bannerCaseName === BannerContentOptions.NEEDS_PROVIDER_RELINK &&
            relinkedProvider !== null
              ? text(relinkedProvider)
              : text()}
          </span>
          {linkUrl && (
            <LinkButton
              url={linkUrl}
              to={{ url: linkUrl }}
              data-testid={`button-${dataTestBannerNotification}`}>
              <div className={styles.button}>
                <span className={styles.buttonText}>{linkText()}</span>
                {icon}
              </div>
            </LinkButton>
          )}
        </div>
        {canDismiss ? (
          <div role="button" tabIndex={0} className={styles.closeButton} onClick={onDismiss}>
            <WhiteXIcon />
          </div>
        ) : undefined}
      </div>
    </div>
  );
}

export default Banner;
