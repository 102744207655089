import { useState, useEffect, useMemo } from 'react';
import { Platform } from '../types/platforms';
import { providerToPlatformMap } from '../utils/platformMappings';

export const useErrorHandling = () => {
  const [error, setError] = useState('');
  const [isClosingErrorBox, setIsClosingErrorBox] = useState(false);

  useEffect(() => {
    const { search, href } = window.location;
    const queryParams = new URLSearchParams(search);
    const errorToastMessage = queryParams.get('toast') || queryParams.get('message');
    const hasSuccess = href.includes('success=true');

    if (!hasSuccess && errorToastMessage) {
      setError(errorToastMessage);
    }
  }, []);

  const handleCloseErrorBox = () => {
    setIsClosingErrorBox(true);
    setTimeout(() => {
      setError('');
      setIsClosingErrorBox(false);
    }, 300);
  };

  return { error, setError, isClosingErrorBox, handleCloseErrorBox };
};

export const usePlatformManagement = (selectedPlatforms: Platform[], userIdentities: any[]) => {
  const [connectedPlatforms, setConnectedPlatforms] = useState<Platform[]>(['Email']);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [loadingPlatform, setLoadingPlatform] = useState<string | null>(null);

  useEffect(() => {
    const mappedPlatforms = userIdentities
      .map(
        ({ provider }: { provider: keyof typeof providerToPlatformMap }) =>
          providerToPlatformMap[provider]
      )
      .filter(Boolean);
    setConnectedPlatforms((prev) => [...new Set([...prev, ...mappedPlatforms])]);

    const identityWithAvatar = userIdentities.find(
      ({ cachedProfileImageUrl }) => cachedProfileImageUrl
    );
    if (identityWithAvatar?.cachedProfileImageUrl) {
      setAvatarUrl(identityWithAvatar.cachedProfileImageUrl);
    }
  }, [userIdentities]);

  const filteredConnectedPlatforms = useMemo(
    () =>
      connectedPlatforms.filter(
        (platform) =>
          platform !== 'Facebook' && (platform === 'Email' || selectedPlatforms.includes(platform))
      ),
    [connectedPlatforms, selectedPlatforms]
  );

  return {
    connectedPlatforms,
    filteredConnectedPlatforms,
    avatarUrl,
    loadingPlatform,
    setLoadingPlatform
  };
};

export const useFlowState = (filteredConnectedPlatforms: Platform[], totalPlatforms: number) => {
  const [isUnfinishedFlow, setIsUnfinishedFlow] = useState(false);
  const [isFinishedFlow, setIsFinishedFlow] = useState(false);
  const [showInstagramInstructions, setShowInstagramInstructions] = useState(false);

  useEffect(() => {
    if (filteredConnectedPlatforms.length === totalPlatforms) {
      setIsFinishedFlow(true);
    }
  }, [filteredConnectedPlatforms.length, totalPlatforms]);

  return {
    isUnfinishedFlow,
    setIsUnfinishedFlow,
    isFinishedFlow,
    showInstagramInstructions,
    setShowInstagramInstructions
  };
};
