import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CheckboxMuiProps } from './CheckboxMuiProps';
import { FiltersCheckboxChecked, FiltersCheckboxUnChecked } from '../../icons';

const StyledFormControlLabel = styled(FormControlLabel)({
  color: '#404040',
  fontSize: '16px',

  '@media (max-width: 780px)': {
    marginLeft: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  }
});

function CheckboxMui(props: CheckboxMuiProps) {
  const { testID, label, checked, onChange, ...rest } = props;
  const matches = useMediaQuery('(max-width:780px)');

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          {...rest}
          disableRipple
          icon={<FiltersCheckboxUnChecked />}
          checkedIcon={<FiltersCheckboxChecked />}
          onChange={onChange}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 25
            },
            '&.Mui-checked': {
              color: '#000000'
            }
          }}
          data-testid={testID}
        />
      }
      label={label}
      checked={checked}
    />
  );
}

export default CheckboxMui;
